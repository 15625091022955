import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from "reactstrap";
import { ROLES_PERMISSION_MANAGEMENT_ADMIN, USER_MANAGEMENT_ADMIN } from '../../../global/PageNames';
import { BsArrowLeft } from "react-icons/bs";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Sidebar } from 'primereact/sidebar';
import { VscEdit, VscTrash } from "react-icons/vsc";
import { BASE_URL, APIS_ENDPOINT } from '../../../global/server';
import { DELETE_DATA, GAT_DATA, POST_DATA, PUT_DATA } from '../../../global/apiType';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Roles() {
    const [isUpdate, setIsUpdate] = useState(false);
    const [createRolePopUp, setCreateRolePopUp] = useState(false);
    const [renderItems, setRenderItems] = useState(false);

    //Save User
    const [role, setRole] = useState("");
    const [roleId, setRoleId] = useState("");
    const [rolesList, setRolesList] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        getRoles()
    }, []);

    //get ALl roles
    async function getRoles() {
        let url = BASE_URL + APIS_ENDPOINT.roleList;
        let item = JSON.parse(localStorage.getItem('GUSD-user-token'));
        let token = item?.value
        let rolesList = await GAT_DATA(url, token);
        if (rolesList) {
            setRolesList(rolesList)
        }
        setRenderItems(true)
    }

    const backHandler = () => {
        navigate(`/${USER_MANAGEMENT_ADMIN}`);
    }

    const actionsBodyTemplate = (role) => {
        return (
            <>
                <button className='border-0 bg-white' onClick={() => editRole(role)}>
                    <VscEdit size={20} style={{ marginRight: 15 }} />
                </button>

                <button className='border-0 bg-white' onClick={() => {
                    confirmDialog({
                        message: 'Do you want to delete this record?',
                        header: 'Delete Confirmation',
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        accept: () => deleteRole(role)
                    });
                }} >
                    <VscTrash size={20} style={{ marginRight: 15 }} />
                </button>
            </>
        )
    };

    const deleteRole = async (roleDetails) => {
        let token = APIS_ENDPOINT.bearerToken;
        let url = BASE_URL + APIS_ENDPOINT.roleList + "/" + roleDetails.id;
        let data = { name: role };
        const response = await DELETE_DATA(url, data, token);
        if (response) {
            getRoles()
            toast.success("Deleted")
        }
    }

    const editRole = (roleDetails) => {
        setIsUpdate(true)
        setRoleId(roleDetails.id)
        setRole(roleDetails.name)
        setCreateRolePopUp(true)
    }

    async function saveRole() {
        if (isUpdate) {
            //UPDATE ROLE
            if (role) {
                let token = APIS_ENDPOINT.bearerToken;
                let url = BASE_URL + APIS_ENDPOINT.roleList + "/" + roleId;
                let data = { name: role };
                const response = await PUT_DATA(url, data, token);
                if (response) {
                    clearAll(); getRoles(); toast.success("Updated.")
                }
            }
            else { toast.error("Enter Role") }
        } else {
            //CREATE NEW ROLE
            if (role) {
                let token = APIS_ENDPOINT.bearerToken;
                let url = BASE_URL + APIS_ENDPOINT.roleList;
                let data = { name: role };
                const response = await POST_DATA(url, data, token);
                if (response.id) {
                    clearAll(); getRoles(); toast.success("Success.")
                }
            } else { toast.error("Enter Role")}
        }
    }

    const clearAll = () => {
        setIsUpdate(false);
        setRole("")
        setCreateRolePopUp(false)
    }


    return (
        < >
            <Container>
                <div className='mt-10 ]'>
                    <div className='meeting_report_file_heading_box'>
                        <p onClick={() => backHandler()} style={{ cursor: 'pointer' }} className='report-name-second' ><BsArrowLeft size={20} /> Return to Users</p>
                    </div>
                </div>

                {
                    renderItems &&
                    <>
                        <div style={{ textAlign: "end", marginBottom: 10 }}>
                            <Button style={{ marginRight: 15, backgroundColor: '#113699' }} size='small' label="Create Role " onClick={() => { clearAll(); setCreateRolePopUp(true) }} />
                            <Button style={{ backgroundColor: '#113699' }} size='small' label="Permissions " onClick={() => navigate(`/${ROLES_PERMISSION_MANAGEMENT_ADMIN}`)} />
                        </div>

                        <DataTable showGridlines value={rolesList} >
                            <Column field="name" header="Role"></Column>
                            <Column align={"center"} header="Actions" body={actionsBodyTemplate}></Column>
                        </DataTable>
                    </>
                }


                <>
                    <div style={{ textAlign: "center" }}>
                        {
                            !renderItems &&
                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                        }
                    </div>
                </>


                <Sidebar visible={createRolePopUp} position='right'
                    style={{ width: '50vw', height: "100%", position: "relative" }}
                    onHide={() => setCreateRolePopUp(false)}

                >
                    <div className="">
                        <div style={{ color: "#0a6dc2", fontSize: 20, fontWeight: "bold" }}>Create New Role</div>
                        <div style={{ fontSize: 14, fontWeight: "bold" }}>Role</div>
                    </div>


                    <div style={{ marginTop: 20 }}>
                        <div className='col-md-12' style={{ marginTop: 20 }}>
                            <label style={{ fontSize: 14, marginBottom: 5 }} className='d-block'>Role Name</label>
                            <InputText type='email' style={{ width: "100%" }} className="p-inputtext-sm" value={role} onChange={(e) => setRole(e.target.value)} />
                        </div>

                        <div style={{ position: "absolute", bottom: 20, right: 20, margin: 10 }}>
                            <Button style={{ marginRight: 20 }} size='small' label="Cancel" severity="secondary" text raised onClick={() => setCreateRolePopUp(false)} />
                            <Button style={{ backgroundColor: '#113699' }} size='small' label={isUpdate ? "Update" : "Save"} onClick={() => saveRole()} />
                        </div>

                    </div>

                </Sidebar>

                <ConfirmDialog />
            </Container>
        </>
    )
}

export default Roles;

