/* eslint-disable no-prototype-builtins */
import React, { useState, useContext, useRef, useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import "./reportSection.css";
import { AiOutlineEye, AiOutlineEdit } from "react-icons/ai";
import { GoKebabVertical } from "react-icons/go";
import { BsArrowLeft } from "react-icons/bs";
import { Edit2, Edit, Add, Trash } from "iconsax-react";
// import { FiPlus } from "react-icons/fi";
import { TbGripHorizontal } from "react-icons/tb";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { RiDeleteBinLine, RiSave2Fill } from "react-icons/ri";
import { RxDragHandleDots2 } from "react-icons/rx";
import { BsRocketTakeoffFill } from "react-icons/bs";
import Form from "react-bootstrap/Form";
import { AuthContext } from "../../../components/ContextAPI/ContextAPI";
import { useNavigate } from "react-router";
import { PageNames } from "../../../global";
import { Sidebar } from "primereact/sidebar";
import { Editor } from "primereact/editor";
import { Toast } from "primereact/toast";
import { APIS_ENDPOINT, BASE_URL } from "../../../global/server";
import { GAT_DATA, POST_DATA, POST_DATA_FORMDATA, PUT_DATA } from "../../../global/apiType";
import PDFViewer from "../../../components/PDFViewer";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { ProgressSpinner } from "primereact/progressspinner";
import { DASHBOARD } from "../../../global/PageNames";
import { FiLogOut } from "react-icons/fi";
import { ADMINDASHBOARD } from "../../../global/PageNames";
import { FcCancel } from "react-icons/fc";
import { FiCheck } from "react-icons/fi";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';

const ReportSectionForAdmin = () => {
   const { reportSectionDetails, reportCurrentSectionDetails } = useContext(AuthContext);
   let reportCurrentData = JSON.parse(localStorage.getItem("report_section_data"));
   let sectionData = JSON.parse(localStorage.getItem("section_data"));
   const UserPermissions = JSON.parse(localStorage.getItem('UserPermissions'));
   const UserRole = JSON.parse(localStorage.getItem('roleId'));
   const UserId = JSON.parse(localStorage.getItem('userId'));

   const navigate = useNavigate();
   const toast = useRef(null);
   //Report name title
   const [reportNameTitle, setReportNameTitle] = useState(false);
   const handleReportNameTitle = () => {
      setReportNameTitle((reportNameTitle) => !reportNameTitle);
   };

   //! DragDropContext and state and data
   const [currentColumns, setCurrentColumns] = useState(reportSectionDetails?.sections ?? sectionData?.sections);



   const handleDragEnd = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = currentColumns[result.source.index];
      const updatedColumns = [...currentColumns];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      const updatedColumnsWithPosition = updatedColumns.map((column, index) => ({
         ...column,
         position: index,
      }));
      setCurrentColumns(updatedColumnsWithPosition);
   };
   //select Item item state and handler
   const [selectedItem, setSelectedItem] = useState(sectionData?.sections[0]?.name ?? "");
   const [selectedItemSectionId, setSelectedItemSectionId] = useState(sectionData?.sections[0]?.id ?? "");
   const [isSectionApproved, setIsSectionApproved] = useState(false);
   const handleSelect = (item) => {
      setSelectedItemSectionId(item.id);
      setSelectedItem(item.name);
      setIsSectionApproved(item.ReportTemplateSection.status)
   };
   const [reportName, setReportName] = useState(reportSectionDetails?.name ?? sectionData?.name);
   const [optionStatevalue, setOptionStatevalue] = useState(['']);
   //! Agenda Cover State and handler
   //FOR NAME AND ADDRESS
   const [nameAC, setNameAC] = useState("");
   const [addressAC, setAddressAC] = useState("");
   //FOR TITLE
   const [titleAC, setTitleAC] = useState("");
   const handleTitle = (event) => {
      setTitleAC(event.target.value);
   };
   //MEETING TYPE
   const [meetingTypeAC, setMeetingTypeAC] = useState("");
   const handleMeetingTypeAC = (event) => {
      setMeetingTypeAC(event.target.value);
   };

   //FOR DATE
   const [dateAC, setDateAC] = useState("");
   const handleDateAC = (event) => {
      setDateAC(event.target.value);
   };
   //FOR MEETING NUMBER
   const [meetingNumberAC, setMeetingNumberAC] = useState("");
   const handleMeetingNumberAC = (event) => {
      setMeetingNumberAC(event.target.value);
   };

   //FOR IMAGE COVER
   const [imageCoverAC, setImageCoverAC] = useState({ key: "", image: "" });
   const [imageCoverLocalAC, setImageCoverLocalAC] = useState("./logo192.png");
   const handleImageCoverAC = async (event) => {
      let token = APIS_ENDPOINT.bearerToken;
      let url = "https://reportbuilder.hexalytics.in/gusd/file";
      // let url = "https://gusd-reportbuilder.hexalytics.in/gusd/file"; ----- >UAt Url
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      try {
         let getImageUrl = await POST_DATA_FORMDATA(url, formData, token);
         setImageCoverAC({ key: getImageUrl.key, image: getImageUrl.attachment });
      } catch (error) {
         // eslint-disable-next-line no-console
         console.log("error: ", error);
      }
      setImageCoverLocalAC(URL.createObjectURL(event.target.files[0]));
   };
   //! end Agenda Cover State and handler

   //!Agenda State and handler
   //FOR NAME AND ADDRESS
   const [nameAndAddressAgenda, setNameAndAddressAgenda] = useState("");

   //FOR TITLE
   const [titleA, setTitleA] = useState("");
   const handleTitleA = (event) => {
      setTitleA(event.target.value);
   };
   //MEETING TYPE
   // const [meetingTypeA, setMeetingTypeA] = useState("");
   // const handleMeetingTypeA = (event) => {
   //     setMeetingTypeA(event.target.value);
   // };

   //FOR DATE
   const [dateA, setDateA] = useState("");
   const handleDateA = (event) => {
      setDateA(event.target.value);
   };
   //FOR MEETING NUMBER
   const [meetingNumberA, setMeetingNumberA] = useState("");
   const handleMeetingNumberA = (event) => {
      setMeetingNumberA(event.target.value);
   };
   //! end Agenda State and handler

   // Setup Agenda cancle and save
   // const handleCancelSetupAgenda = () => {
   // };

   // sideBar state and handler for setup agenda
   const [showSetupTable, setShowSetupTable] = useState(false);
   const [sendTime, setSendTime] = useState(false);

   const [setupAgenda, setSetupAgenda] = useState(false);

   const handleSetupAgenda = () => {
      setSendTime(false);
      setSetupAgenda(true);
      //   setShowSetupTable(false);
   };

   const handleSaveSetupAgenda = () => {
      setSetupAgenda((newValue) => !newValue);
      setSendTime(true);
      setShowSetupTable(true);
   };

   // console.log(showSetupTable);
   // console.log(sendTime);

   // sideBar state and handler
   const [visibleFrom, setVisibleFrom] = useState(false);
   const handleVisibleFrom = () => {
      setPreviewReportTitle("");
      setTo("Board of Education");
      seFrom("Dr. Vivian Ekchian, Superintendent");
      setPreparedBy("");
      setShortDescription("");
      setDetailedDescription("");
      setAttachments("");
      setVisibleFrom((newValue) => !newValue);
   };

   const [previewReportTitle, setPreviewReportTitle] = useState("");
   const [shortDescription, setShortDescription] = useState("");
   const [detailedDescription, setDetailedDescription] = useState("");
   //adding
   const [to, setTo] = useState("Board of Education");
   const [from, seFrom] = useState("Dr. Vivian Ekchian, Superintendent");
   const [preparedBy, setPreparedBy] = useState("");
   const [subject, setSubject] = useState("");
   const [boardPriorities, setBoardPriorities] = useState("");
   const [attachments, setAttachments] = useState({
      name: "",
      key: "",
      fileContent: "",
      attachments: "",
   });
   const handleAttachments = async (event) => {
      let token = APIS_ENDPOINT.bearerToken;
      let url = `${BASE_URL}/gusd/file`;
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      try {
         let getImageUrl = await POST_DATA_FORMDATA(url, formData, token);
         setAttachments({
            name: file.name,
            key: getImageUrl.key,
            fileContent: getImageUrl.html,
            attachments: getImageUrl.attachment,
         });
      } catch (error) {
         // eslint-disable-next-line no-console
         console.log("error: ", error);
      }
   };
   // end sideBar state and handler

   //Opening Report Section data
   const [openings, setOpenings] = useState([]);
   const handleDragOpenings = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = openings[result.source.index];
      const updatedColumns = [...openings];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      setOpenings(updatedColumns);
   };
   //Acknowledgements and Recognition
   const [acknowledgmentsAndRecordings, setAcknowledgmentsAndRecordings] = useState([]);
   const handleDragAcknowledgmentsAndRecordings = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = acknowledgmentsAndRecordings[result.source.index];
      const updatedColumns = [...acknowledgmentsAndRecordings];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      setAcknowledgmentsAndRecordings(updatedColumns);
   };
   //Presentation
   const [presentation, setPresentation] = useState([]);
   const handleDragPresentation = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = presentation[result.source.index];
      const updatedColumns = [...presentation];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      setPresentation(updatedColumns);
   };

   //Student Board Member Report  Student Board Memeber Report
   const [studentAndMember, setStudentAndMember] = useState([]);
   const handleStudentAndMember = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = studentAndMember[result.source.index];
      const updatedColumns = [...studentAndMember];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      setStudentAndMember(updatedColumns);
   };
   // Public Communication
   const [publicCommunication, setPublicCommunication] = useState([]);
   const handlePublicCommunication = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = publicCommunication[result.source.index];
      const updatedColumns = [...publicCommunication];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      setPublicCommunication(updatedColumns);
   };
   //Information Section
   const [informationSection, setInformationSection] = useState([]);
   const handleDragInformationSection = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = informationSection[result.source.index];
      const updatedColumns = [...informationSection];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      setInformationSection(updatedColumns);
   };
   //Action Section
   const [actionSection, setActionSection] = useState([]);
   const handleDragActionSection = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = actionSection[result.source.index];
      const updatedColumns = [...actionSection];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      setActionSection(updatedColumns);
   };
   //Consent Section
   const [consentSection, setConsentSection] = useState([]);
   const handleDragConsentSection = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = consentSection[result.source.index];
      const updatedColumns = [...consentSection];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      setConsentSection(updatedColumns);
   };

   // Reports and Correspondence
   const [reportsAndCorrespondence, setReportsAndCorrespondence] = useState([]);
   const handleDragReportsAndCorrespondence = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = reportsAndCorrespondence[result.source.index];
      const updatedColumns = [...reportsAndCorrespondence];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      setReportsAndCorrespondence(updatedColumns);
   };

   //Adjournment
   const [adjournment, setAdjournment] = useState([]);
   const handleDragAdjournment = (result) => {
      if (!result.destination) {
         return;
      }
      const draggedColumn = adjournment[result.source.index];
      const updatedColumns = [...adjournment];
      updatedColumns.splice(result.source.index, 1);
      updatedColumns.splice(result.destination.index, 0, draggedColumn);
      setAdjournment(updatedColumns);
   };

   const [editWidget, setEditWidget] = useState(false);
   const [editWidgetId, setEditWidgetId] = useState();
   const handleEdit = (item) => {
      let { id, name, WidgetAttribute } = item;
      setEditWidgetId(id);
      setEditWidget(true);
      setVisibleFrom(true);
      setPreviewReportTitle(name);
      setTo(WidgetAttribute.to);
      seFrom(WidgetAttribute.from);
      setPreparedBy(WidgetAttribute.preparedBy);
      setShortDescription(WidgetAttribute.shortDescription);
      setDetailedDescription(WidgetAttribute.detailedDescription);
      setAttachments(WidgetAttribute.attachment);
   };

   const handleSaveSectionData = () => {
      const sections = {
         Openings: { state: openings, setState: setOpenings },
         "Acknowledgements and Recognition": {
            state: acknowledgmentsAndRecordings,
            setState: setAcknowledgmentsAndRecordings,
         },
         Presentation: { state: presentation, setState: setPresentation },
         "Student Board Member Report": {
            state: studentAndMember,
            setState: setStudentAndMember,
         },
         "Public Communication": {
            state: publicCommunication,
            setState: setPublicCommunication,
         },
         "Information Section": {
            state: informationSection,
            setState: setInformationSection,
         },
         "Action Section": { state: actionSection, setState: setActionSection },
         "Consent Section": { state: consentSection, setState: setConsentSection },
         "Reports and Correspondence": {
            state: reportsAndCorrespondence,
            setState: setReportsAndCorrespondence,
         },
         Adjournment: { state: adjournment, setState: setAdjournment },
      };

      const { state, setState } = sections[selectedItem];
      if (previewReportTitle != "") {
         // Generate a unique ID
         const generateUniqueId = () => {
            let uniqueId = state.length + 1;
            while (state.find((item) => item.id === uniqueId)) {
               uniqueId++;
            }
            return uniqueId;
         };

         const formData = {
            id: generateUniqueId(),
            name: previewReportTitle,
            value: previewReportTitle,
            WidgetAttribute: {
               attributetitle: previewReportTitle,
               shortDescription: shortDescription,
               detailedDescription: detailedDescription,
               fileContent: attachments.fileContent,
               attachment: attachments,
               to: to,
               from: from,
               preparedBy: preparedBy,
               subject: subject,
               selectBoardProperties: boardPriorities,
            },
         };
         // console.log("attachments == == = " + JSON.stringify(formData))

         const isDuplicate = state.some((item) => item.name === formData.name);

         if (!isDuplicate) {
            setState((current) => [...current, formData]);
         }

         setPreviewReportTitle("");
         setShortDescription("");
         setDetailedDescription("");
         setAttachments("");
         handleVisibleFrom();
         setEditWidgetId();
         setEditWidget(false);
      } else {
         toast.current.show({
            severity: "error",
            summary: "Please Fill Title field",
            life: 3000,
         });
      }
   };

   //Validation for agenda amd agenda cover
   const handleValidation = ({ status }) => {
      const validation = {
         nameAC: "Please Fill Name of Agenda cover",
         addressAC: "Please Fill Address of Agenda cover",
         titleAC: "Please Fill Title of Agenda cover",
         meetingTypeAC: "Please Fill Meeting type of Agenda cover",
         meetingNumberAC: "Please Fill Meeting Number of Agenda cover",
         dateAC: "Please Fill Date of Agenda cover",
         //Agenda
         nameAndAddressAgenda: "Please Fill Name and Address of Agenda",
         titleA: "Please Fill Title of Agenda",
         // meetingNumberA: "Please Fill Meeting Number of Agenda",
      };

      for (const property in validation) {
         if (!validation.hasOwnProperty(property)) {
            continue;
         }

         if (!eval(property) || eval(property) === "") {
            toast.current.show({
               severity: "error",
               summary: validation[property],
               life: 3000,
            });
            return false;
         }
      }
      sendToDB({ status });
   };

   //SAVE DATA INTO DB
   const [reportID, setReportID] = useState(null);
   const sendToDB = async ({ status }) => {
      const updatedData = currentColumns.map((item, index) => {
         return {
            id: item.id,
            name: item.name,
            position: item.position ?? index,
            time: sendTime ? item.time : "00:00",
            widgets: [],
         };
      });

      let AgendaCover = [
         {
            id: 1,
            value: nameAC,
         },
         {
            id: 2,
            value: addressAC,
         },
         {
            id: 3,
            value: titleAC,
         },
         {
            id: 4,
            value: meetingTypeAC,
         },
         {
            id: 5,
            value: dateAC,
         },
         {
            id: 6,
            value: meetingNumberAC,
         },
         {
            id: 7,
            value: `${imageCoverAC.key}`,
         },
      ];
      let Agenda = [
         {
            id: 8,
            value: nameAndAddressAgenda,
         },
         {
            id: 9,
            value: titleA,
         },
         {
            id: 10,
            value: JSON.stringify(optionStatevalue),
         },
         {
            id: 11,
            value: dateA,
         },
         {
            id: 12,
            value: meetingNumberA,
         },
      ];

      const sections = {
         1: AgendaCover,
         2: Agenda,
         3: openings,
         4: acknowledgmentsAndRecordings,
         5: presentation,
         6: studentAndMember,
         7: publicCommunication,
         8: informationSection,
         9: actionSection,
         10: consentSection,
         11: reportsAndCorrespondence,
         12: adjournment,
      };

      for (let i = 0; i < updatedData.length; i++) {
         const sectionId = updatedData[i].id;
         if (sections.hasOwnProperty(sectionId)) {
            updatedData[i].widgets = sections[sectionId];
         }
      }
      let data = {
         report_name: reportName,
         status: status,
         template: {
            templateName: reportName,
            sections: updatedData,
         },
      };
      if (reportID == null) {
         try {
            let token = APIS_ENDPOINT.bearerToken;
            let url = `${BASE_URL}/gusd/report`;
            const response = await POST_DATA(url, data, token);

            setReportID(response.report.id);
            toast.current.show({
               severity: "success",
               summary: `Report ${status == "Progress" ? "saved" : status}`,
               life: 3000,
            });
            if (status == "Publish") {
               navigate(`/${DASHBOARD}`);
            }
         } catch (error) {
            toast.current.show({
               severity: "error",
               summary: error?.message,
               life: 3000,
            });
         }
      } else {
         try {
            let token = APIS_ENDPOINT.bearerToken;
            let url = `${BASE_URL}/gusd/report/${reportID}`;
            const response = await PUT_DATA(url, data, token);

            setReportID(response.template.report_id);
            toast.current.show({
               severity: "success",
               summary: `Report ${status == "Progress" ? "saved" : status}`,
               life: 3000,
            });
            if (status == "Publish") {
               navigate(`/${DASHBOARD}`);
            }
         } catch (error) {
            toast.current.show({
               severity: "error",
               summary: error?.message,
               life: 3000,
            });
         }
      }
   };
   //END SAVE DATA INTO DB

   const handleEditSectionData = () => {
      const sections = {
         Openings: { state: openings, setState: setOpenings },
         "Acknowledgements and Recognition": {
            state: acknowledgmentsAndRecordings,
            setState: setAcknowledgmentsAndRecordings,
         },
         Presentation: { state: presentation, setState: setPresentation },
         "Student Board Member Report": {
            state: studentAndMember,
            setState: setStudentAndMember,
         },
         "Public Communication": {
            state: publicCommunication,
            setState: setPublicCommunication,
         },
         "Information Section": {
            state: informationSection,
            setState: setInformationSection,
         },
         "Action Section": { state: actionSection, setState: setActionSection },
         "Consent Section": { state: consentSection, setState: setConsentSection },
         "Reports and Correspondence": {
            state: reportsAndCorrespondence,
            setState: setReportsAndCorrespondence,
         },
         Adjournment: { state: adjournment, setState: setAdjournment },
      };

      const { state, setState } = sections[selectedItem];
      if (previewReportTitle != "") {
         const formData = {
            id: editWidgetId,
            name: previewReportTitle,
            value: previewReportTitle,
            WidgetAttribute: {
               attributetitle: previewReportTitle,
               shortDescription: shortDescription,
               detailedDescription: detailedDescription,
               attachment: attachments,
               fileContent: attachments.fileContent,
               to: to,
               from: from,
               preparedBy: preparedBy,
               subject: subject,
               selectBoardProperties: boardPriorities,
            },
         };

         const existingIndex = state.findIndex((item) => item.id === formData.id);

         if (existingIndex !== -1) {
            // Update the existing item with the same ID
            const updatedState = [...state];
            updatedState[existingIndex] = formData;
            setState(updatedState);
         } else {
            setState((current) => [...current, formData]);
         }

         setPreviewReportTitle("");
         setShortDescription("");
         setDetailedDescription("");
         setAttachments("");
         handleVisibleFrom();
         setEditWidgetId();
         setEditWidget(false);
      } else {
         toast.current.show({
            severity: "error",
            summary: "Please Fill Title field",
            life: 3000,
         });
      }
   };

   const [visibleCoverPage, setVisibleCoverPage] = useState(false);
   const handleVisibleCoverPage = () => {
      setVisibleCoverPage((visibleCoverPage) => !visibleCoverPage);
   };

   const handleRemoveImageAPI = async (key) => {
      let token = APIS_ENDPOINT.bearerToken;
      let url = `${BASE_URL}/gusd/file`;
      let data = { key: key };
      try {
         let response = await fetch(url, {
            method: "DELETE",
            headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
         });

         // Check if the request was successful
         if (response.ok) {
            setAttachments(null);
         } else {
            // eslint-disable-next-line no-console
            console.log("Request failed with status:", response.status);
         }
      } catch (error) {
         // eslint-disable-next-line no-console
         console.log("Error:", error);
         alert("Failed to delete image");
      }
   };

   const [pdfViewer, setPdfViewer] = useState(false);
   const handlePdfViewer = () => {
      setPdfViewer((pdfViewer) => !pdfViewer);
   };

   const [isLoading, setLoading] = useState(false);

   function checkTemplateTime(template) {
      for (let i = 0; i < template.template.usedSection.length; i++) {
         if (template.template.usedSection[i].ReportTemplateSection.time !== "00:00" && template.template.usedSection[i].ReportTemplateSection.time !== null && template.template.usedSection[i].ReportTemplateSection.time !== undefined) {
            return true;
         }
      }
      return false;
   }
   const reportData = async () => {
      setLoading(true);
      //       setTimeout(() => {
      // debugger
      //          document.querySelectorAll('.p-editor-toolbar .ql-formats')[0].remove();
      //       }, 1500);
      try {
         let isObjectEmpty = (objectName) => { return Object.keys(objectName).length === 0 };
         let checkObjectNull = isObjectEmpty(reportCurrentSectionDetails);
         if (!checkObjectNull) {
            let url = `${BASE_URL}/gusd/report/${reportCurrentSectionDetails.id ?? reportCurrentData?.id}`;
            let token = APIS_ENDPOINT.bearerToken;
            let reportData = await GAT_DATA(url, token);
            sectionData = reportData.template.usedSection;
            let hasDifferentTime = checkTemplateTime(reportData);
            setLoading(false);
            setShowSetupTable(hasDifferentTime)
            setReportName(reportData.name);

            const updatedArray = reportData?.template.usedSection.map((obj) => {
               // if (obj.id === item.id) {
               return {
                  ...obj,
                  time: obj.ReportTemplateSection.time,
               };
               // }
               // return obj;
            });

            setCurrentColumns(updatedArray ?? "");
            setSelectedItem(reportData?.template?.usedSection[0]?.name);

            const sections = {
               Openings: { state: openings, setState: setOpenings },
               "Acknowledgements and Recognition": {
                  state: acknowledgmentsAndRecordings,
                  setState: setAcknowledgmentsAndRecordings,
               },
               Presentation: { state: presentation, setState: setPresentation },
               "Student Board Member Report": {
                  state: studentAndMember,
                  setState: setStudentAndMember,
               },
               "Public Communication": {
                  state: publicCommunication,
                  setState: setPublicCommunication,
               },
               "Information Section": {
                  state: informationSection,
                  setState: setInformationSection,
               },
               "Action Section": { state: actionSection, setState: setActionSection },
               "Consent Section": {
                  state: consentSection,
                  setState: setConsentSection,
               },
               "Reports and Correspondence": {
                  state: reportsAndCorrespondence,
                  setState: setReportsAndCorrespondence,
               },
               Adjournment: { state: adjournment, setState: setAdjournment },
            };

            setReportID(reportData.id);
            for (let i = 0; i < reportData?.template?.usedSection.length; i++) {
               const sectionId = reportData?.template?.usedSection[i].id;
               if (sectionId > 2) {
                  // eslint-disable-next-line no-unused-vars
                  const { state, setState } = sections[reportData?.template?.usedSection[i].name];
                  setState(reportData?.template?.usedSection[i].wedgets);
               } else if (sectionId == 1) {
                  let tempAC = reportData?.template?.usedSection[i].wedgets;
                  for (let ac = 0; ac < tempAC.length; ac++) {
                     if (tempAC[ac].name == "Name") {
                        setNameAC(tempAC[ac].widgetValues[0].value);
                     } else if (tempAC[ac].name == "Address") {
                        setAddressAC(tempAC[ac].widgetValues[0].value);
                     } else if (tempAC[ac].name == "Title") {
                        setTitleAC(tempAC[ac].widgetValues[0].value);
                     } else if (tempAC[ac].name == "Meeting Type") {
                        setMeetingTypeAC(tempAC[ac].widgetValues[0].value);
                     } else if (tempAC[ac].name == "Date") {
                        setDateAC(tempAC[ac].widgetValues[0].value);
                     } else if (tempAC[ac].name == "Meeting Number") {
                        setMeetingNumberAC(tempAC[ac].widgetValues[0].value);
                     } else if (tempAC[ac].name == "Image Cover") {
                        setImageCoverAC({
                           key: "",
                           image: tempAC[ac].widgetValues[0].value,
                        });
                     }
                  }
               } else if (sectionId == 2) {
                  let tempA = reportData?.template?.usedSection[i].wedgets;
                  for (let A = 0; A < tempA.length; A++) {
                     if (tempA[A].name == "Name And Address") {
                        setNameAndAddressAgenda(tempA[A].widgetValues[0].value);
                     } else if (tempA[A].name == "Title") {
                        setTitleA(tempA[A].widgetValues[0].value);
                     } else if (tempA[A].name == "Quotes") {
                        let quotesarray = JSON.parse(tempA[A].widgetValues[0].value);
                        setOptionStatevalue(quotesarray.length > 0 ? quotesarray : ['']);
                     } else if (tempA[A].name == "Date") {
                        setDateA(tempA[A].widgetValues[0].value);
                     } else if (tempA[A].name == "Meeting Number") {
                        setMeetingNumberA(tempA[A].widgetValues[0].value);
                     }
                  }
               }
            }
         } else {
            setLoading(false);
         }
      } catch (error) {
         setLoading(false);
      }
   };

   // Check if the item is expired and remove it from local storage
   function checkItemExpiration() {
      const item = JSON.parse(localStorage.getItem('GUSD-user-token'));
      const itemGoogle = JSON.parse(localStorage.getItem('GUSD-user-Google-token'));
      if (item && new Date().getTime() > item?.expirationTime) {
         localStorage.removeItem('GUSD-user-token');
         window.location.reload();
         window.location = '/'
      }

      if (itemGoogle && new Date().getTime() > item?.expirationTime) {
         localStorage.removeItem('GUSD-user-Google-token');
         window.location.reload();
         window.location = '/'
      }
   }

   setInterval(checkItemExpiration, 600000);

   useEffect(() => {
      reportData();
   }, []);
   const addAdditionalQuotes = () => {
      setOptionStatevalue([...optionStatevalue, '']);
   }

   const setHandlerQuoteA = ({ e, i }) => {
      let namevalue = e.target.value;
      let CurrentElementData = [...optionStatevalue[i]];
      let ValueUpdatekey = [...CurrentElementData, namevalue];
      let arr = [...optionStatevalue];
      arr[i] = ValueUpdatekey[ValueUpdatekey.length - 1];
      // setFilesArray(attachmentObject)
      setOptionStatevalue(arr);
   }
   const stateDeletHandle = (i) => {
      if (optionStatevalue.length > 1) {
         let data = [...optionStatevalue];
         data.splice(i, 1)
         setOptionStatevalue(data);
      }
   }

    //-------------- Change Status --------------
    const changeStatusSection = (statusId) => {
      let status = ""
      if (statusId == 1) { status = "Approve" }
      else if (statusId == 2) { status = "Deny" }
      else if (statusId == 3) { status = "Send for approval" }

      confirmDialog({
         message: 'Do you want to ' + status + ' this record?',
         header: 'Confirmation',
         icon: 'pi pi-info-circle',
         acceptClassName: 'p-button-danger',
         accept: () => ChangeStatusAPI(statusId)
      });

   }

   const ChangeStatusAPI = async (statusId) => {
      let data = {
         "reportId": reportID,
         "sectionId": selectedItemSectionId,
         "userId": UserId.value,
         "status": statusId,
         "commentsData": []
      }
      let token = APIS_ENDPOINT.bearerToken;
      let url = BASE_URL + APIS_ENDPOINT.sectionApproveDeny;
      const response = await POST_DATA(url, data, token);
      if(response){ reportData() }
   }
   //-------------- Change Status --------------

   return (
      <Container>
         {isLoading ? (
            <div className="loading__">
               <ProgressSpinner />
            </div>
         ) : null}
         <div className="report-section-container-grey">
            <div className="top-container">
               <div className="">
                  <p className="report-name">
                     <BsArrowLeft onClick={() => navigate(`/${ADMINDASHBOARD}`)} /> Report Name
                  </p>
                  {reportNameTitle ? (
                     <div style={{ display: "flex" }}>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                           <Form.Control className="editfield" type="text" value={reportName} onChange={(e) => setReportName(e.target.value)} />
                        </Form.Group>
                        <Edit size={30} style={{ padding: "5px" }} onClick={handleReportNameTitle} />
                     </div>
                  ) : (
                     <div style={{ display: "flex" }}>
                        <p className="report-date">{reportName}</p>
                        <Edit size={30} style={{ padding: "5px" }} onClick={handleReportNameTitle} />
                     </div>
                  )}
               </div>
               <div className="group-btn">
                  <button type="button" className="btn preview-button" onClick={() => handlePdfViewer()} disabled={reportID == null ? true : false}>
                     <AiOutlineEye /> Preview
                  </button>
                  <button type="button" className="btn reject-button" onClick={() => handleValidation({ status: "Progress" })}>
                     {" "}
                     <FcCancel /> Deny
                  </button>
                  <button type="button" className="btn approve-button" onClick={() => handleValidation({ status: "Publish" })}>
                     {" "}
                     <FiCheck /> Approve{" "}
                  </button>
                  <button type="button" className="btn menu-button">
                     {" "}
                     <GoKebabVertical />{" "}
                  </button>
               </div>
            </div>
            <div className="sub-container-report">
               <div className="report-section">
                  <div className="report-section-header">
                     <h6>Report Section</h6>
                     {/* <div>
                <button className='btn add-btn btn-add-sm'>Add <FiPlus /></button>
              </div> */}
                  </div>
                  <div style={{ display: "block" }}>
                     <DragDropContext onDragEnd={handleDragEnd}>
                        <table className="dragDrop-table">
                           <Droppable droppableId="columns">
                              {(provided) => (
                                 <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                    {currentColumns?.map((item, index) => {
                                       return (
                                          <Draggable key={index} draggableId={index.toString()} index={index}>
                                             {(provided) => (
                                                <div {...provided.draggableProps} ref={provided.innerRef} onClick={() => handleSelect(item)} className={`list-of-items ${selectedItem === item.name ? "selected" : ""}`}>
                                                   <div style={{ display: "flex" }}>
                                                      <div {...provided.dragHandleProps} className="handle">
                                                         <RxDragHandleDots2 />
                                                      </div>
                                                      <div> {item.name} </div>
                                                   </div>
                                                   <div className="delete">
                                                      {" "}
                                                      <RiDeleteBinLine style={{ color: "red" }} />{" "}
                                                   </div>
                                                </div>
                                             )}
                                          </Draggable>
                                       );
                                    })}
                                    {provided.placeholder}
                                 </tbody>
                              )}
                           </Droppable>
                        </table>
                     </DragDropContext>
                  </div>
               </div>
               <div className="report-details">
                  <div className="report-section-header">
                     <div>{selectedItem}</div>
                  </div>
                  <div className="report-details-section">
                     {(() => {
                        switch (selectedItem) {
                           case "Agenda":
                              return (
                                 <div className="agenda-container">
                                    <Form>
                                       <div className="agenda_cover_container">
                                          <div className="agenda_cover_items_one">
                                             <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                <Form.Label>
                                                   Name and Address <span style={{ color: "red" }}>*</span>
                                                </Form.Label>
                                                <Editor value={nameAndAddressAgenda} onTextChange={(e) => setNameAndAddressAgenda(e.htmlValue)} style={{ height: "150px" }} />
                                             </Form.Group>
                                             <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>
                                                   Title <span style={{ color: "red" }}>*</span>
                                                </Form.Label>
                                                <Form.Control value={titleA} onChange={handleTitleA} type="text" placeholder="" />
                                             </Form.Group>
                                             {optionStatevalue.map((item, i) => (
                                                <>
                                                   <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                      <Form.Label>Quote <span style={{ color: "red" }}>*</span></Form.Label>
                                                      <div style={{ display: "flex", alignItems: "center", gap: "12px" }}>
                                                         {/* quoteA */}
                                                         <Form.Control value={item} onChange={(e) => setHandlerQuoteA({ e, i })} type="text" placeholder="" />
                                                         <div onClick={() => stateDeletHandle(i)}>
                                                            <Trash style={{ width: "18px", color: "gray" }} />
                                                         </div>
                                                      </div>
                                                   </Form.Group>
                                                </>
                                             ))}
                                             <div className="btn btn-text d-flex px-0 justify-content-end" onClick={() => addAdditionalQuotes()}>Add additional quotes <span className="btn-icon"><Add /></span></div>
                                          </div>
                                          <div className="agenda_cover_items_two">
                                             <div className="agenda_cover_box">
                                                <div className="agenda_cover_box_item_one">
                                                   <Form.Label>Date</Form.Label>
                                                   <Form.Control value={dateA} onChange={handleDateA} type="date" placeholder="" />
                                                </div>
                                                <div className="agenda_cover_box_item_two">
                                                   <Form.Label>Meeting Number</Form.Label>
                                                   <Form.Control value={meetingNumberA} onChange={handleMeetingNumberA} type="text" placeholder="" />
                                                </div>
                                             </div>
                                             <div >
                                                {/* (showSetupTable &&  */}
                                                {showSetupTable ? (
                                                   <>
                                                      <table className="table table-bordered rounded-table table-striped my-4">
                                                         <tbody>
                                                            {currentColumns?.map((item) => {
                                                               if (item.name === "Agenda" || item.name === "Agenda Cover") {
                                                                  return null; // Skip rendering this item
                                                               }
                                                               return (
                                                                  <tr key={item.id}>
                                                                     <td>{item.name}</td>
                                                                     <td>{item.ReportTemplateSection == undefined ? item.time : item.ReportTemplateSection.time}</td>
                                                                  </tr>
                                                               );
                                                            })}
                                                         </tbody>
                                                      </table>
                                                      <Button className="btn btn-dotted my-3" onClick={() => setSetupAgenda(true)}> <AiOutlineEdit /> edit setup</Button>
                                                   </>
                                                ) : (
                                                   <button type="button" className="btn btn-dotted my-3" onClick={() => handleSetupAgenda()}>
                                                      Setup agenda <Add style={{ marginLeft: "15px" }} />
                                                   </button>
                                                )}
                                             </div>
                                          </div>
                                       </div>
                                    </Form>
                                 </div>
                              );
                           case "Agenda Cover":
                              return (
                                 <div className="agenda-container">
                                    <Form>
                                       <div className="agenda_cover_container">
                                          <div className="agenda_cover_items_one">
                                             <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>
                                                   Name <span style={{ color: "red" }}>*</span>
                                                </Form.Label>
                                                <Form.Control value={nameAC} onChange={(e) => setNameAC(e.target.value)} type="text" placeholder="" />
                                             </Form.Group>
                                             <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>
                                                   Address <span style={{ color: "red" }}>*</span>
                                                </Form.Label>
                                                <Editor value={addressAC} onTextChange={(e) => setAddressAC(e.htmlValue)} style={{ height: "150px" }} />
                                             </Form.Group>
                                             <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>
                                                   Title <span style={{ color: "red" }}>*</span>
                                                </Form.Label>
                                                <Form.Control value={titleAC} onChange={handleTitle} type="text" placeholder="" />
                                             </Form.Group>
                                             <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                <Form.Label>
                                                   Meeting type <span style={{ color: "red" }}>*</span>
                                                </Form.Label>
                                                <Form.Control value={meetingTypeAC} onChange={handleMeetingTypeAC} type="text" placeholder="" />
                                             </Form.Group>
                                          </div>
                                          <div className="agenda_cover_items_two">
                                             <div className="agenda_cover_box">
                                                <div className="agenda_cover_box_item_one">
                                                   <Form.Label>
                                                      Date <span style={{ color: "red" }}>*</span>
                                                   </Form.Label>
                                                   <Form.Control value={dateAC} onChange={handleDateAC} type="date" placeholder="" />
                                                </div>
                                                <div className="agenda_cover_box_item_two">
                                                   <Form.Label>
                                                      Meeting Number <span style={{ color: "red" }}>*</span>
                                                   </Form.Label>
                                                   <Form.Control value={meetingNumberAC} onChange={handleMeetingNumberAC} type="text" placeholder="" />
                                                </div>
                                             </div>
                                             <div className="agenda_image_select">
                                                <Form.Label>Image cover</Form.Label>
                                                <Form.Control className="file-dropzone" onChange={handleImageCoverAC} type="file" placeholder="" />
                                                {/* {imageCoverAC && (
                                                                    <div>
                                                                        <img src={imageCoverAC?.image} alt="Selected cover" width="200" />
                                                                    </div>
                                                                )} */}
                                                {imageCoverLocalAC && (
                                                   <div>
                                                      <img src={imageCoverLocalAC} alt="Selected cover" width="200" />
                                                   </div>
                                                )}
                                             </div>
                                          </div>
                                       </div>
                                    </Form>
                                 </div>
                              );
                           case "Openings":
                              return (
                                 <>
                                    <div className="openings-container" style={{ display: "block" }}>
                                       <DragDropContext onDragEnd={handleDragOpenings}>
                                          <table className="dragDrop-table">
                                             <Droppable droppableId="columns">
                                                {(provided) => (
                                                   <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                      {openings?.map((item, index) => {
                                                         return (
                                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                               {(provided) => (
                                                                  <div className="agenda_report_opening_container" {...provided.draggableProps} ref={provided.innerRef}>
                                                                     <div className="agenda_report_title">
                                                                        <span className="agenda_grip_display" {...provided.dragHandleProps}>
                                                                           <TbGripHorizontal />
                                                                        </span>
                                                                        <span className="agenda_number_color">{index + 1}</span>
                                                                        <span className="agenda_name_color">{item.name}</span>
                                                                     </div>
                                                                     <div
                                                                        style={{
                                                                           display: "flex",
                                                                           gap: "15px",
                                                                        }}
                                                                     >
                                                                        {

                                                                        }
                                                                        <button type="button" className="agenda_preview_btn">
                                                                           <AiOutlineEye /> Preview
                                                                        </button>
                                                                        <button type="button" className="agenda_preview_btn">
                                                                           <AiOutlineEye /> Preview
                                                                        </button>
                                                                        <button type="button" className="agenda_preview_btn" onClick={() => handleEdit(item)}>
                                                                           <AiOutlineEdit /> Edit
                                                                        </button>
                                                                        <button type="button" className="agenda_delete_btn" onClick={() => setOpenings((current) => current.filter((data) => data.id !== item.id))}>
                                                                           <RiDeleteBinLine />
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               )}
                                                            </Draggable>
                                                         );
                                                      })}
                                                      {provided.placeholder}
                                                   </tbody>
                                                )}
                                             </Droppable>
                                          </table>
                                       </DragDropContext>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                       <button type="button" className="btn save-button" onClick={() => handleVisibleFrom()}>
                                          {" "}
                                          Add
                                       </button>
                                    </div>{" "}
                                 </>
                              );
                           case "Acknowledgements and Recognition":
                              return (
                                 <>
                                    <div style={{ display: "block" }}>
                                       <DragDropContext onDragEnd={handleDragAcknowledgmentsAndRecordings}>
                                          <table className="dragDrop-table">
                                             <Droppable droppableId="columns">
                                                {(provided) => (
                                                   <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                      {acknowledgmentsAndRecordings?.map((item, index) => {
                                                         return (
                                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                               {(provided) => (
                                                                  <div className="agenda_report_opening_container" {...provided.draggableProps} ref={provided.innerRef}>
                                                                     <div className="agenda_report_title">
                                                                        <span className="agenda_grip_display" {...provided.dragHandleProps}>
                                                                           <TbGripHorizontal />
                                                                        </span>
                                                                        <span className="agenda_number_color">{index + 1}</span>
                                                                        <span className="agenda_name_color">{item.name}</span>
                                                                     </div>
                                                                     <div
                                                                        style={{
                                                                           display: "flex",
                                                                           gap: "15px",
                                                                        }}
                                                                     >
                                                                        <button type="button" className="agenda_preview_btn">
                                                                           <AiOutlineEye /> Preview
                                                                        </button>
                                                                        <button type="button" className="agenda_preview_btn" onClick={() => handleEdit(item)}>
                                                                           <AiOutlineEdit /> Edit
                                                                        </button>
                                                                        <button type="button" className="agenda_delete_btn" onClick={() => setAcknowledgmentsAndRecordings((current) => current.filter((data) => data.id !== item.id))}>
                                                                           <RiDeleteBinLine />
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               )}
                                                            </Draggable>
                                                         );
                                                      })}
                                                      {provided.placeholder}
                                                   </tbody>
                                                )}
                                             </Droppable>
                                          </table>
                                       </DragDropContext>
                                    </div>
                                    <button type="button" className="btn save-button" onClick={() => handleVisibleFrom()}>
                                       {" "}
                                       Add
                                    </button>
                                 </>
                              );
                           case "Presentation":
                              return (
                                 <>
                                    <div style={{ display: "block" }}>
                                       <DragDropContext onDragEnd={handleDragPresentation}>
                                          <table className="dragDrop-table">
                                             <Droppable droppableId="columns">
                                                {(provided) => (
                                                   <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                      {presentation?.map((item, index) => {
                                                         return (
                                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                               {(provided) => (
                                                                  <div className="agenda_report_opening_container" {...provided.draggableProps} ref={provided.innerRef}>
                                                                     <div className="agenda_report_title">
                                                                        <span className="agenda_grip_display" {...provided.dragHandleProps}>
                                                                           <TbGripHorizontal />
                                                                        </span>
                                                                        <span className="agenda_number_color">{index + 1}</span>
                                                                        <span className="agenda_name_color">{item.name}</span>
                                                                     </div>
                                                                     <div
                                                                        style={{
                                                                           display: "flex",
                                                                           gap: "15px",
                                                                        }}
                                                                     >
                                                                        <button type="button" className="agenda_preview_btn">
                                                                           <AiOutlineEye /> Preview
                                                                        </button>
                                                                        <button type="button" className="agenda_preview_btn" onClick={() => handleEdit(item)}>
                                                                           <AiOutlineEdit /> Edit
                                                                        </button>
                                                                        <button type="button" className="agenda_delete_btn" onClick={() => setPresentation((current) => current.filter((data) => data.id !== item.id))}>
                                                                           <RiDeleteBinLine />
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               )}
                                                            </Draggable>
                                                         );
                                                      })}
                                                      {provided.placeholder}
                                                   </tbody>
                                                )}
                                             </Droppable>
                                          </table>
                                       </DragDropContext>
                                    </div>
                                    <button type="button" className="btn save-button" onClick={() => handleVisibleFrom()}>
                                       {" "}
                                       Add
                                    </button>
                                 </>
                              );
                           case "Student Board Member Report":
                              return (
                                 <>
                                    <div style={{ display: "block" }}>
                                       <DragDropContext onDragEnd={handleStudentAndMember}>
                                          <table className="dragDrop-table">
                                             <Droppable droppableId="columns">
                                                {(provided) => (
                                                   <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                      {studentAndMember?.map((item, index) => {
                                                         return (
                                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                               {(provided) => (
                                                                  <div className="agenda_report_opening_container" {...provided.draggableProps} ref={provided.innerRef}>
                                                                     <div className="agenda_report_title">
                                                                        <span className="agenda_grip_display" {...provided.dragHandleProps}>
                                                                           <TbGripHorizontal />
                                                                        </span>
                                                                        <span className="agenda_number_color">{index + 1}</span>
                                                                        <span className="agenda_name_color">{item.name}</span>
                                                                     </div>
                                                                     <div
                                                                        style={{
                                                                           display: "flex",
                                                                           gap: "15px",
                                                                        }}
                                                                     >
                                                                        <button type="button" className="agenda_preview_btn">
                                                                           <AiOutlineEye /> Preview
                                                                        </button>
                                                                        <button type="button" className="agenda_preview_btn" onClick={() => handleEdit(item)}>
                                                                           <AiOutlineEdit /> Edit
                                                                        </button>
                                                                        <button type="button" className="agenda_delete_btn" onClick={() => setStudentAndMember((current) => current.filter((data) => data.id !== item.id))}>
                                                                           <RiDeleteBinLine />
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               )}
                                                            </Draggable>
                                                         );
                                                      })}
                                                      {provided.placeholder}
                                                   </tbody>
                                                )}
                                             </Droppable>
                                          </table>
                                       </DragDropContext>
                                    </div>
                                    <button type="button" className="btn save-button" onClick={() => handleVisibleFrom()}>
                                       {" "}
                                       Add
                                    </button>
                                 </>
                              );
                           case "Public Communication":
                              return (
                                 <>
                                    <div style={{ display: "block" }}>
                                       <DragDropContext onDragEnd={handlePublicCommunication}>
                                          <table className="dragDrop-table">
                                             <Droppable droppableId="columns">
                                                {(provided) => (
                                                   <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                      {publicCommunication?.map((item, index) => {
                                                         return (
                                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                               {(provided) => (
                                                                  <div className="agenda_report_opening_container" {...provided.draggableProps} ref={provided.innerRef}>
                                                                     <div className="agenda_report_title">
                                                                        <span className="agenda_grip_display" {...provided.dragHandleProps}>
                                                                           <TbGripHorizontal />
                                                                        </span>
                                                                        <span className="agenda_number_color">{index + 1}</span>
                                                                        <span className="agenda_name_color">{item.name}</span>
                                                                     </div>
                                                                     <div
                                                                        style={{
                                                                           display: "flex",
                                                                           gap: "15px",
                                                                        }}
                                                                     >
                                                                        <button type="button" className="agenda_preview_btn">
                                                                           <AiOutlineEye /> Preview
                                                                        </button>
                                                                        <button type="button" className="agenda_preview_btn" onClick={() => handleEdit(item)}>
                                                                           <AiOutlineEdit /> Edit
                                                                        </button>
                                                                        <button type="button" className="agenda_delete_btn" onClick={() => setPublicCommunication((current) => current.filter((data) => data.id !== item.id))}>
                                                                           <RiDeleteBinLine />
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               )}
                                                            </Draggable>
                                                         );
                                                      })}
                                                      {provided.placeholder}
                                                   </tbody>
                                                )}
                                             </Droppable>
                                          </table>
                                       </DragDropContext>
                                    </div>
                                    <button type="button" className="btn save-button" onClick={() => handleVisibleFrom()}>
                                       {" "}
                                       Add
                                    </button>
                                 </>
                              );
                           case "Information Section":
                              return (
                                 <>
                                    <div style={{ display: "block" }}>
                                       <DragDropContext onDragEnd={handleDragInformationSection}>
                                          <table className="dragDrop-table">
                                             <Droppable droppableId="columns">
                                                {(provided) => (
                                                   <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                      {informationSection?.map((item, index) => {
                                                         return (
                                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                               {(provided) => (
                                                                  <div className="agenda_report_opening_container" {...provided.draggableProps} ref={provided.innerRef}>
                                                                     <div className="agenda_report_title">
                                                                        <span className="agenda_grip_display" {...provided.dragHandleProps}>
                                                                           <TbGripHorizontal />
                                                                        </span>
                                                                        <span className="agenda_number_color">{index + 1}</span>
                                                                        <span className="agenda_name_color">{item.name}</span>
                                                                     </div>
                                                                     <div
                                                                        style={{
                                                                           display: "flex",
                                                                           gap: "15px",
                                                                        }}
                                                                     >
                                                                        <button type="button" className="agenda_preview_btn">
                                                                           <AiOutlineEye /> Preview
                                                                        </button>
                                                                        <button type="button" className="agenda_preview_btn" onClick={() => handleEdit(item)}>
                                                                           <AiOutlineEdit /> Edit
                                                                        </button>
                                                                        <button type="button" className="agenda_delete_btn" onClick={() => setInformationSection((current) => current.filter((data) => data.id !== item.id))}>
                                                                           <RiDeleteBinLine />
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               )}
                                                            </Draggable>
                                                         );
                                                      })}
                                                      {provided.placeholder}
                                                   </tbody>
                                                )}
                                             </Droppable>
                                          </table>
                                       </DragDropContext>
                                    </div>
                                    <button type="button" className="btn save-button" onClick={() => handleVisibleFrom()}>
                                       {" "}
                                       Add
                                    </button>
                                 </>
                              );
                           case "Action Section":
                              return (
                                 <>
                                    <div style={{ display: "block" }}>
                                       <DragDropContext onDragEnd={handleDragActionSection}>
                                          <table className="dragDrop-table">
                                             <Droppable droppableId="columns">
                                                {(provided) => (
                                                   <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                      {actionSection?.map((item, index) => {
                                                         return (
                                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                               {(provided) => (
                                                                  <div className="agenda_report_opening_container" {...provided.draggableProps} ref={provided.innerRef}>
                                                                     <div className="agenda_report_title">
                                                                        <span className="agenda_grip_display" {...provided.dragHandleProps}>
                                                                           <TbGripHorizontal />
                                                                        </span>
                                                                        <span className="agenda_number_color">{index + 1}</span>
                                                                        <span className="agenda_name_color">{item.name}</span>
                                                                     </div>
                                                                     <div
                                                                        style={{
                                                                           display: "flex",
                                                                           gap: "15px",
                                                                        }}
                                                                     >
                                                                        <button type="button" className="agenda_preview_btn">
                                                                           <AiOutlineEye /> Preview
                                                                        </button>
                                                                        <button type="button" className="agenda_preview_btn" onClick={() => handleEdit(item)}>
                                                                           <AiOutlineEdit /> Edit
                                                                        </button>
                                                                        <button type="button" className="agenda_delete_btn" onClick={() => setActionSection((current) => current.filter((data) => data.id !== item.id))}>
                                                                           <RiDeleteBinLine />
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               )}
                                                            </Draggable>
                                                         );
                                                      })}
                                                      {provided.placeholder}
                                                   </tbody>
                                                )}
                                             </Droppable>
                                          </table>
                                       </DragDropContext>
                                    </div>
                                    <button type="button" className="btn save-button" onClick={() => handleVisibleFrom()}>
                                       {" "}
                                       Add
                                    </button>
                                 </>
                              );
                           case "Consent Section":
                              return (
                                 <>
                                    <div style={{ display: "block" }}>
                                       <DragDropContext onDragEnd={handleDragConsentSection}>
                                          <table className="dragDrop-table">
                                             <Droppable droppableId="columns">
                                                {(provided) => (
                                                   <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                      {consentSection?.map((item, index) => {
                                                         return (
                                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                               {(provided) => (
                                                                  <div className="agenda_report_opening_container" {...provided.draggableProps} ref={provided.innerRef}>
                                                                     <div className="agenda_report_title">
                                                                        <span className="agenda_grip_display" {...provided.dragHandleProps}>
                                                                           <TbGripHorizontal />
                                                                        </span>
                                                                        <span className="agenda_number_color">{index + 1}</span>
                                                                        <span className="agenda_name_color">{item.name}</span>
                                                                     </div>
                                                                     <div
                                                                        style={{
                                                                           display: "flex",
                                                                           gap: "15px",
                                                                        }}
                                                                     >
                                                                        <button type="button" className="agenda_preview_btn">
                                                                           <AiOutlineEye /> Preview
                                                                        </button>
                                                                        <button type="button" className="agenda_preview_btn" onClick={() => handleEdit(item)}>
                                                                           <AiOutlineEdit /> Edit
                                                                        </button>
                                                                        <button type="button" className="agenda_delete_btn" onClick={() => setConsentSection((current) => current.filter((data) => data.id !== item.id))}>
                                                                           <RiDeleteBinLine />
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               )}
                                                            </Draggable>
                                                         );
                                                      })}
                                                      {provided.placeholder}
                                                   </tbody>
                                                )}
                                             </Droppable>
                                          </table>
                                       </DragDropContext>
                                    </div>
                                    <button type="button" className="btn save-button" onClick={() => handleVisibleFrom()}>
                                       {" "}
                                       Add
                                    </button>
                                 </>
                              );
                           case "Reports and Correspondence":
                              return (
                                 <>
                                    <div style={{ display: "block" }}>
                                       <DragDropContext onDragEnd={handleDragReportsAndCorrespondence}>
                                          <table className="dragDrop-table">
                                             <Droppable droppableId="columns">
                                                {(provided) => (
                                                   <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                      {reportsAndCorrespondence?.map((item, index) => {
                                                         return (
                                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                               {(provided) => (
                                                                  <div className="agenda_report_opening_container" {...provided.draggableProps} ref={provided.innerRef}>
                                                                     <div className="agenda_report_title">
                                                                        <span className="agenda_grip_display" {...provided.dragHandleProps}>
                                                                           <TbGripHorizontal />
                                                                        </span>
                                                                        <span className="agenda_number_color">{index + 1}</span>
                                                                        <span className="agenda_name_color">{item.name}</span>
                                                                     </div>
                                                                     <div
                                                                        style={{
                                                                           display: "flex",
                                                                           gap: "15px",
                                                                        }}
                                                                     >
                                                                        <button type="button" className="agenda_preview_btn">
                                                                           <AiOutlineEye /> Preview
                                                                        </button>
                                                                        <button type="button" className="agenda_preview_btn" onClick={() => handleEdit(item)}>
                                                                           <AiOutlineEdit /> Edit
                                                                        </button>
                                                                        <button type="button" className="agenda_delete_btn" onClick={() => setReportsAndCorrespondence((current) => current.filter((data) => data.id !== item.id))}>
                                                                           <RiDeleteBinLine />
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               )}
                                                            </Draggable>
                                                         );
                                                      })}
                                                      {provided.placeholder}
                                                   </tbody>
                                                )}
                                             </Droppable>
                                          </table>
                                       </DragDropContext>
                                    </div>
                                    <button type="button" className="btn save-button" onClick={() => handleVisibleFrom()}>
                                       {" "}
                                       Add
                                    </button>
                                 </>
                              );
                           case "Adjournment":
                              return (
                                 <>
                                    <div style={{ display: "block" }}>
                                       <DragDropContext onDragEnd={handleDragAdjournment}>
                                          <table className="dragDrop-table">
                                             <Droppable droppableId="columns">
                                                {(provided) => (
                                                   <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                                      {adjournment?.map((item, index) => {
                                                         return (
                                                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                                               {(provided) => (
                                                                  <div className="agenda_report_opening_container" {...provided.draggableProps} ref={provided.innerRef}>
                                                                     <div className="agenda_report_title">
                                                                        <span className="agenda_grip_display" {...provided.dragHandleProps}>
                                                                           <TbGripHorizontal />
                                                                        </span>
                                                                        <span className="agenda_number_color">{index + 1}</span>
                                                                        <span className="agenda_name_color">{item.name}</span>
                                                                     </div>
                                                                     <div
                                                                        style={{
                                                                           display: "flex",
                                                                           gap: "15px",
                                                                        }}
                                                                     >
                                                                        <button type="button" className="agenda_preview_btn">
                                                                           <AiOutlineEye /> Preview
                                                                        </button>
                                                                        <button type="button" className="agenda_preview_btn" onClick={() => handleEdit(item)}>
                                                                           <AiOutlineEdit /> Edit
                                                                        </button>
                                                                        <button type="button" className="agenda_delete_btn" onClick={() => setAdjournment((current) => current.filter((data) => data.id !== item.id))}>
                                                                           <RiDeleteBinLine />
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                               )}
                                                            </Draggable>
                                                         );
                                                      })}
                                                      {provided.placeholder}
                                                   </tbody>
                                                )}
                                             </Droppable>
                                          </table>
                                       </DragDropContext>
                                    </div>
                                    <button type="button" className="btn save-button" onClick={() => handleVisibleFrom()}>
                                       {" "}
                                       Add
                                    </button>
                                 </>
                              );
                           default:
                              return null;
                        }
                     })()}
                  </div>

                  {/* Setup Agenda Slider */}
                  <Sidebar visible={setupAgenda} position="right" onHide={() => setSetupAgenda(false)}>
                     <div className="">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                           <div>
                              <h6 className="report-preview-header mb-4">Setup agenda</h6>
                           </div>
                        </div>
                     </div>
                     {/* (item?.name !== "Agenda" && item?.name !== "Agenda Cover") && */}
                     <div>
                        <DragDropContext onDragEnd={handleDragEnd}>
                           <table className="dragDrop-table">
                              <Droppable droppableId="columns">
                                 {(provided) => (
                                    <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                       {currentColumns?.map((item, index) => {
                                          if (item?.name === "Agenda" || item?.name === "Agenda Cover") {
                                             return null; // Skip rendering the Draggable component
                                          }

                                          const [timeDaketi, setTimeDaketi] = useState({ error: false, message: "", id: "" });
                                          const handleTime = (time, item) => {
                                             const updatedColumns = [...currentColumns];
                                             const timeExists = updatedColumns.some((obj) => (obj.ReportTemplateSection == undefined ? obj.time : obj.ReportTemplateSection.time) === time);
                                             if (timeExists) {
                                                // Handle the error or show a message indicating the time already exists
                                                // console.error('Error: Time already exists');
                                                setTimeDaketi({
                                                   error: true,
                                                   message: "Error: Time already exists",
                                                   id: item.id,
                                                });
                                                return;
                                             }
                                             const updatedArray = updatedColumns.map((obj) => {
                                                if (obj.id === item.id) {
                                                   return {
                                                      ...obj,
                                                      time: time,
                                                      ReportTemplateSection: {
                                                         ...obj.ReportTemplateSection,
                                                         time: time
                                                      }
                                                   };
                                                }
                                                return obj;
                                             });
                                             setCurrentColumns(updatedArray);
                                             setTimeDaketi({
                                                error: false,
                                                message: "Error: Time already exists",
                                                id: item.id,
                                             });
                                          };
                                          return (
                                             <Draggable key={index} draggableId={index.toString()} index={index}>
                                                {(provided) => (
                                                   <>
                                                      <div {...provided.draggableProps} ref={provided.innerRef} className={`list-of-items`}>
                                                         <div style={{ display: "flex" }}>
                                                            <div {...provided.dragHandleProps} className="handle">
                                                               <RxDragHandleDots2 />
                                                            </div>
                                                            <div>{item.name}</div>
                                                         </div>
                                                         <input value={item.ReportTemplateSection == undefined ? item.time : item.ReportTemplateSection.time} className="input-time" onChange={(e) => handleTime(e.target.value, item)} type="time" placeholder="00:00 pm" />
                                                      </div>
                                                      <span>{timeDaketi.id == item.id && timeDaketi.error ? <>{timeDaketi.message}</> : null}</span>
                                                   </>
                                                )}
                                             </Draggable>
                                          );
                                       })}
                                       {provided.placeholder}
                                    </tbody>
                                 )}
                              </Droppable>
                           </table>
                        </DragDropContext>
                     </div>
                     <div className="SetupAgenda-buttons">
                        <Button label="Cancel" onClick={() => setSetupAgenda(false)} className="button-text-link">
                           {" "}
                           Cancel
                        </Button>
                        <Button label="Save" style={{ width: "112px" }} onClick={handleSaveSetupAgenda} className="btn btn-primary-md">
                           {" "}
                           Save
                        </Button>
                     </div>
                  </Sidebar>

                  <Sidebar visible={visibleFrom} position="right" onHide={() => setVisibleFrom(false)}>
                     <div className="save-content-wrapper ">
                        <button type="button" className="btn btn-primary large-rounded" onClick={editWidget ? handleEditSectionData : handleSaveSectionData}>
                           {" "}
                           Save Content
                        </button>
                     </div>
                     <div className="">
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                           <div>
                              <h6 className="report-preview-header my-0">Report Preview</h6>
                              <p className="preview-head-sub">select the section to compose your report</p>
                           </div>
                        </div>
                     </div>
                     <div>
                        <div className="collapse-parent-section">
                           <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <Form.Label>Title </Form.Label>
                              <div
                                 style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    gap: "20px",
                                 }}
                              >
                                 <Form.Control value={previewReportTitle} onChange={(e) => setPreviewReportTitle(e.target.value)} type="text" placeholder="" />
                                 <Button className="save-cover-btn" onClick={() => handleVisibleCoverPage()}>
                                    Save Cover Page <Edit2 size={18} />
                                 </Button>
                              </div>
                           </Form.Group>
                           {visibleCoverPage ? (
                              <div className="collapse-section">
                                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>To</Form.Label>
                                    <Form.Control value={to} onChange={(e) => setTo(e.target.value)} type="text" placeholder="" />
                                 </Form.Group>
                                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>From</Form.Label>
                                    <Form.Control value={from} onChange={(e) => seFrom(e.target.value)} type="text" placeholder="" />
                                 </Form.Group>
                                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Prepared By</Form.Label>
                                    <Form.Control value={preparedBy} onChange={(e) => setPreparedBy(e.target.value)} type="text" placeholder="" />
                                 </Form.Group>
                                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Subject</Form.Label>
                                    <Form.Control value={subject} onChange={(e) => setSubject(e.target.value)} type="text" placeholder="" />
                                 </Form.Group>
                                 <div>
                                    <Form.Label>Select Board Priorities</Form.Label>
                                    <Form.Select className="form-control" aria-label="Default select example" onChange={(e) => setBoardPriorities(e.target.value)}>
                                       <option>Select Board Priorities</option>
                                       <option value="1">One</option>
                                       <option value="2">Two</option>
                                       <option value="3">Three</option>
                                    </Form.Select>
                                 </div>
                                 <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Detailed Description</Form.Label>
                                    <Editor value={detailedDescription} onTextChange={(e) => setDetailedDescription(e.htmlValue)} style={{ height: "150px" }} />
                                 </Form.Group>
                              </div>
                           ) : null}
                        </div>

                        <div className="collapse-parent-section">
                           <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                              <Form.Label>Short Description</Form.Label>
                              <Form.Control value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} as="textarea" rows={3} placeholder="" />
                           </Form.Group>
                           <div>
                              <Form.Group controlId="formFileMultiple" className="mb-3">
                                 <Form.Label>Attachments</Form.Label>
                                 <Form.Control className="file-dropzone" type="file" onChange={handleAttachments} />
                              </Form.Group>
                              {attachments && (
                                 <button onClick={() => handleRemoveImageAPI(attachments?.key)} className="btn btn-link ms-2">
                                    Clear
                                 </button>
                              )}
                           </div>
                        </div>
                     </div>
                  </Sidebar>
                  <Sidebar visible={pdfViewer} position="right" onHide={() => setPdfViewer(false)}>
                     <PDFViewer id={reportID} />
                  </Sidebar>
               </div>
            </div>
         </div>
         <Toast ref={toast} position="bottom-left" />
         <ConfirmDialog />
      </Container>
   );
};

export default ReportSectionForAdmin;
