import React, { useEffect, useState, useContext, useRef } from 'react';
import { Container } from 'react-bootstrap';
import { BsArrowLeft } from 'react-icons/bs';
import { GoKebabVertical } from 'react-icons/go';
import { useNavigate } from 'react-router';
import { PageNames } from '../../global';
import { Sidebar } from 'primereact/sidebar';
import ReportCard from '../../components/ReportCards/index.js';
import { AuthContext } from '../../components/ContextAPI/ContextAPI';
import { APIS_ENDPOINT, BASE_URL } from '../../global/server';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import { GAT_DATA } from '../../global/apiType';
import MeetCard from '../../components/MeetingCard/MeetCard';
import { COPY_EXISTING_REPORT, REPORT_SECTION } from '../../global/PageNames';
const CreateReport = () => {
  const UserPermissions = JSON.parse(localStorage.getItem('UserPermissions'));

  const navigate = useNavigate();
  const toast = useRef(null);
  const { setReportSectionDetails, setReportCurrentSectionDetails } = useContext(AuthContext);
  const [reportTemplate, setReportTemplate] = useState([]);
  const [visibleRight, setVisibleRight] = useState(false);
  const [reportSectionData, setReportSectionData] = useState([]);
  const [approvedReports, setApprovedReports] = useState([]);

  const handleVisible = (data) => {
    setReportSectionData(data);
    setVisibleRight((newValue) => !newValue);
  };
  const handleGoToReportSection = (item) => {
    localStorage.removeItem("report_section_data")
    localStorage.setItem('section_data', JSON.stringify(item));
    setReportSectionDetails(item);
    setReportCurrentSectionDetails([])
    navigate(`/${PageNames.REPORT_SECTION}`);
  };

  const accept = () => {
    let userToken = localStorage.getItem('GUSD-user-token') == null ? false : true;
    let userGoogleToken = localStorage.getItem('GUSD-user-Google-token') == null ? false : true;
    if (userToken) {
      localStorage.removeItem('GUSD-user-token');
    }
    if (userGoogleToken) {
      localStorage.removeItem('GUSD-user-Google-token');
    }
    window.location.reload();
    window.location = '/'
  }
  const reject = () => {
    window.location.reload();
    window.location = '/'
  }


  const confirm1 = () => {
    confirmDialog({
      message: 'your section is expiate please login again',
      header: 'Timeout',
      icon: 'pi pi-exclamation-triangle',
      accept,
      reject
    });
  };

  useEffect(() => {
    getApprovedReports()
    let token = APIS_ENDPOINT.bearerToken;
    const headers = { 'Authorization': `Bearer ${token}` };
    fetch(`${APIS_ENDPOINT.reportSectionTemplate}`, { headers })
      .then(response => response.json())
      .then(data => {
        if (data.error == "jwt expired") {
          confirm1()
        }
        else {
          setReportTemplate(data)
        }
      }
      )
      .catch((error) => {
        if (error) {
          toast.current.show({ severity: 'error', summary: 'error', detail: error.message, life: 3000 });
        }
      })
  }, []);

  var getApprovedReports = async () => {
    let url = BASE_URL + APIS_ENDPOINT.dashboardCurrentReport;
    let token = APIS_ENDPOINT.bearerToken;
    let reportData = await GAT_DATA(url, token);
    if (reportData) {
      // setApprovedReports(reportData)
      let filteredArr = reportData.filter(a => a.status === "Approved")
      if (filteredArr.length) { setApprovedReports(filteredArr) } else { setApprovedReports([]) }
    }
  }

  var createReportBasedOnRecentReport = async (item) => {
    confirmDialog({
      message: 'Do you want to create report?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        let url = BASE_URL + APIS_ENDPOINT.useExistingReport + "/" + item.id;
        console.log(url)
        let token = APIS_ENDPOINT.bearerToken;
        console.log(token)
        let createReport = await GAT_DATA(url, token);
        if (createReport) {
          localStorage.setItem('report_section_data', JSON.stringify(createReport));
          localStorage.removeItem("section_data")
          navigate(`/${COPY_EXISTING_REPORT}`);
          console.log(createReport)
          toast.current.show({
            severity: "success",
            summary: "Report created successfully.",
            life: 3000,
          });
        }
      },
    });
  }

  return (
    <Container>
      <div className='sub-container'>
        <div className=''>
          <p className='report-name-second' onClick={() => navigate(`/${PageNames.DASHBOARD}`)} ><BsArrowLeft size={20} /></p>
          <p className='report-date'>How do you want to Start the Report?</p>
        </div>

        <div className='group-btn' >
          {
            UserPermissions.value["Create New Master Template"] === 1 &&
            <button type="button" className='btn large-primary'> Create a New Template </button>
          }
          {/* <button type="button" className='btn menu-button'> <GoKebabVertical /> </button> */}
        </div>
      </div>
      <p className='heading-sm'>Create from template</p>

      <div className='card-container cardreport'>
        {reportTemplate?.map((item, index) => {
            console.log("reportTemplate",item)
          return (
            <ReportCard
              key={index}
              title={item.name}
              description={item?.description ?? ' '}
              onPress={() => handleGoToReportSection(item)}
              onViewPress={() => handleVisible(item?.sections)}
              primaryBtn="Use This"
              secondaryBtn="Preview"
            />
          );
        })}

        <Sidebar className='Preview-list-container' visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
          <h6>Preview Report</h6>
          <p>select the sections to compose your report</p>
          <ul className='preview-list'>
            {reportSectionData?.map((item, index) =>
              <li className='' key={index}>
                <span>{item?.name}</span>
              </li>
            )}
          </ul>
        </Sidebar>
      </div>


      {
        approvedReports.length &&
        <>
          <p className='heading-sm' style={{ marginTop: 15 }}>Create from recent reports.</p>
          <div className="horizontal-drag-container">
            <div className="scroll-content">
              {approvedReports.map((item, index) => (
                <ReportCard
                  key={index}
                  title={item.name}
                  description={item?.description ?? ' '}
                  onPress={() => createReportBasedOnRecentReport(item)}
                  primaryBtn="Use This"
                />
              ))}
            </div>
          </div>
        </>
      }

      <ConfirmDialog />
      <Toast position="bottom-left" ref={toast} />
    </Container>
  );
};

export default CreateReport;