import React, { useEffect, useState, useContext ,useRef} from 'react';
import { Container } from 'react-bootstrap';
import { BsArrowLeft } from 'react-icons/bs';
import { GoKebabVertical } from 'react-icons/go';
import { useNavigate } from 'react-router';
import { PageNames } from '../../../global';
import { Sidebar } from 'primereact/sidebar';
import ReportCard from '../../../components/ReportCards/index.js';
import { AuthContext } from '../../../components/ContextAPI/ContextAPI';
import { APIS_ENDPOINT } from '../../../global/server';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
const CreateReportForAdmin = () => {
  const navigate = useNavigate();
  const toast = useRef(null);
  const { setReportSectionDetails, setReportCurrentSectionDetails } = useContext(AuthContext);
  const [reportTemplate, setReportTemplate] = useState([]);
  const [visibleRight, setVisibleRight] = useState(false);
  const [reportSectionData, setReportSectionData] = useState([]);

  const handleVisible = (data) => {
    setReportSectionData(data);
    setVisibleRight((newValue) => !newValue);
  };
  const handleGoToReportSection = (item) => {
    localStorage.removeItem("report_section_data")
    localStorage.setItem('section_data', JSON.stringify(item));
    setReportSectionDetails(item);
    setReportCurrentSectionDetails([])
    navigate(`/${PageNames.REPORT_SECTION}`);
  };

  const accept = () => {
    let userToken = localStorage.getItem('GUSD-user-token') == null ? false : true;
    let userGoogleToken = localStorage.getItem('GUSD-user-Google-token') == null ? false : true;
    if (userToken) {
      localStorage.removeItem('GUSD-user-token');
    }
    if (userGoogleToken) {
      localStorage.removeItem('GUSD-user-Google-token');
    }
    window.location.reload();
    window.location = '/'
  }
  const reject = () => {
    window.location.reload();
    window.location = '/'
  }


  const confirm1 = () => {
    confirmDialog({
      message: 'your section is expiate please login again',
      header: 'Timeout',
      icon: 'pi pi-exclamation-triangle',
      accept,
      reject
    });
  };

  useEffect(() => {
    let token = APIS_ENDPOINT.bearerToken;
    const headers = { 'Authorization': `Bearer ${token}` };
    fetch(`${APIS_ENDPOINT.reportSectionTemplate}`, { headers })
      .then(response => response.json())
      .then(data => {
        if (data.error == "jwt expired") {
          confirm1()
        }
        else {
          setReportTemplate(data)
        }
      }
      )
      .catch((error) => {
        if (error) {
          toast.current.show({ severity: 'error', summary: 'error', detail: error.message, life: 3000 });
        }
      })
  }, []);

  return (
    <Container>
      <div className='sub-container'>
        <div className=''>
          <p className='report-name-second' onClick={() => navigate(`/${PageNames.DASHBOARD}`)} ><BsArrowLeft size={20} /></p>
          <p className='report-date'>How do you want to Start the Report?</p>
        </div>

        <div className='group-btn' >
          <button type="button" className='btn large-primary'> Create a New Template </button>
          <button type="button" className='btn menu-button'> <GoKebabVertical /> </button>
        </div>
      </div>
      <p className='heading-sm'>Create from template</p>

      <div className='card-container cardreport'>
        {reportTemplate?.map((item, index) => {
          return (
            <ReportCard
              key={index}
              title={item.name}
              description={item?.description ?? ' '}
              onPress={() => handleGoToReportSection(item)}
              onViewPress={() => handleVisible(item?.sections)}
              primaryBtn="Use This"
              secondaryBtn="Preview"
            />
          );
        })}
        <Sidebar className='Preview-list-container' visible={visibleRight} position="right" onHide={() => setVisibleRight(false)}>
          <h6>Preview Report</h6>
          <p>select the sections to compose your report</p>
          <ul className='preview-list'>
            {reportSectionData?.map((item, index) =>
              <li className='' key={index}>
                <span>{item?.name}</span>
              </li>
            )}
          </ul>
        </Sidebar>
      </div>
      <ConfirmDialog />
      <Toast position="bottom-left" ref={toast} />
    </Container>
  );
};

export default CreateReportForAdmin;