/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { styled } from "styled-components";
const Card = styled.div`
  /* border: 0.5px solid #c5c5c5;
  border-radius: 10px;
  background-color: #ffffff;
  /* overflow: hidden; */
  min-width: 21rem;
  max-width: 21rem;

`;
const CardBody = styled.div`
  ${'' /* padding: 15px; */}
  ${'' /* background-color: #ffff; */}
  border-radius: 10px;
`;
const CardTitle = styled.h4`
font-size:18px;`;

const CardSubtitle = styled.p`
  font-size: 12px;
  color: #c5c5c5;
`;

// const Button = styled.button`
//   background-color: #ffff;
//   border: 1px solid #c5c5c5;
//   border-radius: 10px;
//   width: 50%;
//   cursor: default!important;
// `;
const ButtonOne = styled.button`
  background-color: #ffff;
  border: 1px solid #c5c5c5;
  border-radius: 10px;
  width: 50%;
  cursor: default!important;
`;
const ButtonTwo = styled.button`
  background-color: #ffff;
  border: 1px solid #c5c5c5;
  border-radius: 10px;
  width: 55%;
  cursor: default!important;
`;
const ButtonThree = styled.button`
  background-color: #ffff;
  border: 1px solid #c5c5c5;
  border-radius: 10px;
  width: 35%;
  cursor: default!important;
`;
const ButtonText = styled.p`
  font-style: normal;
  margin: 10px;
  font-size: 14px;
  font-weight: 500;
  color: gray;
`;
const DescriptionText = styled.p`
  font-size: 15px;
  color: gray;
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
           line-clamp: 2; 
   -webkit-box-orient: vertical;
   min-height: 50px;
   max-height: 50px;
`;
const MeetCard = ({ title, subtitle, description, status, onPress }) => {
  const value = status;
  let result;
  switch (value) {
    case 'Progress':
      result = <div> <ButtonOne onClick={onPress} outline color="primary" block>
        <ButtonText> Finish Report </ButtonText>
      </ButtonOne>
        <span className="ReportInProgress">Report in Progress</span>
      </div>;
      break;
    case 'Pending':
      result = <div> <ButtonTwo onClick={onPress} outline color="primary" block>
        <ButtonText> Continue Editing </ButtonText>
      </ButtonTwo>
        <span className="not_published">Not Published</span> </div>;
      break;
    case 'Publish':
      result = <div><ButtonThree onClick={onPress} outline color="primary" block>
        <ButtonText> Open </ButtonText>
      </ButtonThree>
        <span className="published">Published</span>
      </div>;
      break;
    case 'Approved':
      result = <div><ButtonThree onClick={onPress} outline color="primary" block>
        <ButtonText> Open </ButtonText>
      </ButtonThree>
        <span className="published">Report Approved</span>
      </div>;
      break;
    case 'Send For Approval':
      result = <div><ButtonThree onClick={onPress} outline color="primary" block>
        <ButtonText> Open </ButtonText>
      </ButtonThree>
        <span className="waitingForApproval">Report in Pending</span>
      </div>;
      break;
    case 'Rejected':
      result = <div><ButtonThree onClick={onPress} outline block>
        <ButtonText>Open</ButtonText>
      </ButtonThree>
        <span className="rejected">Report Rejected</span>
      </div>;
      break;
    default:
      result = <div>No Result Found</div>;
  }
  return (
    <>
      <Card className="my-4 card">
        <CardBody>
          <CardTitle tag="h3">{title}</CardTitle>
          <CardSubtitle>{subtitle}</CardSubtitle>
          <DescriptionText>{description}</DescriptionText>
          {/* <Button onClick={onPress} outline color="primary" block>
          <ButtonText> Continue Editing </ButtonText>
        </Button> */}
        </CardBody>
        {result}
      </Card>
    </>
  );
};

MeetCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  onPress: PropTypes.func,
};

export default MeetCard;
