/* eslint-disable no-useless-escape */
/* eslint-disable react/prop-types */
import FullCalendar from "@fullcalendar/react";
import { Container } from 'react-bootstrap';
import daygridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { GAT_DATA, POST_DATA_FORMDATA, PUT_DATA, DELETE_DATA, POST_DATA } from '../../../global/apiType';
import { ProgressSpinner } from 'primereact/progressspinner';
import { APIS_ENDPOINT, BASE_URL } from "../../../global/server";
import { InputText } from "primereact/inputtext";
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
// import { AutoComplete } from "primereact/autocomplete";
import { Toast } from 'primereact/toast';
// import { CountryService } from './service/CountryService';
import { Tag } from 'primereact/tag';
import { AttachSquare, Video, SearchNormal, Minus, Add, CloseSquare } from 'iconsax-react';
import { LoadScript, Autocomplete } from '@react-google-maps/api';
import { Editor } from "primereact/editor";
import { useNavigate } from 'react-router';
import { DASHBOARD } from '../../../global/PageNames';
import { BsArrowLeft } from "react-icons/bs";
import { Dialog } from 'primereact/dialog';
import Form from 'react-bootstrap/Form';
import "./Meeting.css";
import { stubFalse } from "lodash";
import { SiGooglemaps } from "react-icons/si";
import moment from "moment";
const MyCalendar = () => {
  //#region Mycalendar States
  const navigate = useNavigate();
  const [enabled, setEnabled] = useState(false);
  const [eventDate, setEventDate] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [holdPage, setHoldPage] = useState(false);
  const [optionStatevalue, setOptionStatevalue] = useState([{ attachment: '', key: '', name: '' }]);
  //#endregion

  // #region  Slider State
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [videoLink, setVideoLink] = useState('');
  const [date, setDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [text, setText] = useState('');
  // const [allChecked, setAllChecked] = useState(false);
  const [sendNoti, setSendNoti] = useState(false);
  // const [countries, setCountries] = useState([]);
  const [tags, setTags] = useState([]);
  const [addreportModel, setAddreportModel] = useState([]);
  const [addreportModelId, setAddreportModelId] = useState('');
  const [addreportModeltitle, setAddreportModeltitle] = useState('');
  const [addGuest, setAddGuest] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(null);
  // const [filteredCountries, setFilteredCountries] = useState(null);
  const [visible, setVisible] = useState(false);
  const [openmodelvedio, setopenmodelvedio] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState({});
  const [editmeeting, setEditMeeting] = useState({});
  const [meetingTitle, setMeetingTitle] = useState(false);
  const [meetingTitlenull, setMeetingTitlenull] = useState(false);
  const [savedate, setSavedate] = useState(false);
  // const [startTimevalue, setStartTimevalue] = useState(false);
  // const [endTimevalue, setEndTimevalue] = useState(false);
  const [videoLinkchek, setVideoLinkchek] = useState(false);
  const [videoThumbnail, setVideoThumbnail] = useState('');
  //#endregion

  // Check if the item is expired and remove it from local storage
  function checkItemExpiration() {
    const item = JSON.parse(localStorage.getItem('GUSD-user-token'));
    const itemGoogle = JSON.parse(localStorage.getItem('GUSD-user-Google-token'));
    if (item && new Date().getTime() > item?.expirationTime) {
      localStorage.removeItem('GUSD-user-token');
      window.location.reload();
      window.location = '/'
    }

    if (itemGoogle && new Date().getTime() > item?.expirationTime) {
      localStorage.removeItem('GUSD-user-Google-token');
      window.location.reload();
      window.location = '/'
    }
  }

  setInterval(checkItemExpiration, 600000);

  useEffect(() => {
    eventDateFunc();
    // CountryService.getCountries().then((data) => setCountries(data));
  }, []);

  //Google Location
  const [address, setAddress] = useState('');
  const [suggestions, setSuggestions] = useState([]);

  const handleLoad = (autocomplete) => {
    // You can use the 'autocomplete' instance for additional operations if needed
    console.log('Autocomplete loaded:', autocomplete);
  };

  const handleInputChange = (e) => {
    setAddress(e.target.value);

    // Fetch suggestions using AutocompleteService
    const service = new window.google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input: e.target.value }, (predictions, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        setSuggestions(predictions.map((prediction) => prediction.description));
      }
    });
  };


  const handleSelect = (place) => {
    setAddress(place);
    setSelectedCountry(place)
    setSuggestions([])
    // Retrieve lat/lng from the selected place
    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
    service.getDetails({ placeId: place.place_id }, (result, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        console.log('Selected address:', result.formatted_address);
        console.log('Latitude:', result.geometry.location.lat());
        console.log('Longitude:', result.geometry.location.lng());
      }
    });
  };

  const handleToggle = (eventClickInfo) => {
    setEnabled((enabled) => !enabled);
    setDate(eventClickInfo.startStr);
  }; // slider Function

  function handleCreateMeeting() {
    setEnabled((enabled) => !enabled);
  }

  const showError = (message) => {
    toast.current.show({ severity: 'error', summary: 'Error', detail: message, life: 3000 });
  }

  const showSuccess = async (message) => {
    toast.current.show({ severity: 'success', summary: 'Success', detail: message, life: 3000 });

  }

  // Covert Time to AM & PM from 24Hr formate
  function convertTimeTo12HourFormat(time) {
    let [hours, minutes] = time.split(':'); // Split the time into hours and minutes

    // Determine if it's AM or PM based on the hour
    let period = (hours >= 12) ? 'pm' : 'am';

    // Convert hours to 12-hour format
    hours = (hours > 12) ? (hours - 12) : hours;
    if (hours === '0') {
      hours = '12';
    }

    return hours + ':' + minutes + ' ' + period;
  }

  // Colour Based on Meeting Date
  const checkMeetingValid = (value) => {
    let dateString = value;
    let date = new Date(dateString);
    let year = date.getFullYear();
    let month = date.getMonth() + 1; // Add 1 because month is zero-based
    let day = date.getDate();
    let validValue = `${year}-${month}-${day}`;
    let currentDate = new Date(); // Get the current date
    let targetDate = new Date(validValue); // The target date you want to compare
    let isCurrentDateGreater =
      currentDate.getFullYear() < targetDate.getFullYear() ||
      (currentDate.getFullYear() === targetDate.getFullYear() &&
        currentDate.getMonth() < targetDate.getMonth()) ||
      (currentDate.getFullYear() === targetDate.getFullYear() &&
        currentDate.getMonth() === targetDate.getMonth() &&
        currentDate.getDate() <= targetDate.getDate());
    // 68c184
    return (isCurrentDateGreater ? '#758fbe' : '#68c184');
  }

  // Event create list call Function
  const eventDateFunc = async () => {
    try {
      let url = BASE_URL + APIS_ENDPOINT.currentMonthMeeting;
      let token = APIS_ENDPOINT.bearerToken;
      let reportData = await GAT_DATA(url, token);
      if ((reportData.length != undefined ? reportData.length != 0 : false) && reportData.message != 'unauthorize user not valid') {
        let events = reportData.map((event) => ({
          meetingTitle: event.title,
          title: event.title + '  ' + convertTimeTo12HourFormat(event.startTime != null ? event.startTime : "00:00"),
          start: event.meetingDate != null ? event.meetingDate.split("T")[0] : '',
          color: checkMeetingValid(event.meetingDate)
        }));
        setEventDate(events);
      } else {
        setEventDate([]);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }

  // Event to show pre-fill meeting details
  const handleEventClick = async (eventClickInfo) => {
    let selectedEvent;
    const event = eventClickInfo.event;
    if (event.extendedProps || event._def.extendedProps) {
      selectedEvent = event.extendedProps == undefined ? event._def.extendedProps.meetingTitle : event.extendedProps.meetingTitle;
    }
    if (selectedEvent) {
      const filteredItems = eventDate.filter((item) => item.meetingTitle == selectedEvent);
      setSelectedMeeting(filteredItems)
      console.log(selectedMeeting)
      if (filteredItems != null || filteredItems != {}) {
        let getData = await editMeetingData(filteredItems[0].meetingTitle);
        setEditMeeting(getData)
        let meetRequermtvalue = checkMeetingValid(getData[0].meetingDate);
        if (getData != null && getData.length != 0 && meetRequermtvalue == '#758fbe') {
          // let [data] = get[Data;
          setHoldPage(true);
          let [data] = getData.filter((item) => (item.title == selectedEvent));
          // console.log(data)
          try {
            let url = BASE_URL + APIS_ENDPOINT.gusdreport;
            let token = APIS_ENDPOINT.bearerToken;
            let reportData = await GAT_DATA(url, token);
            if (reportData.length > 0) {
              let selectedReport = reportData.filter((item) => (item.id == data.reportId));
              setAddreportModeltitle(selectedReport[0].name);
            } else {
              setAddreportModeltitle('');
            }
          } catch (error) {
            setAddreportModeltitle('');
          }
          setTitle(data.title);
          const dateString = data.meetingDate;
          const date = new Date(dateString);
          date.setDate(date.getDate()); // Add 2 days
          const formattedDate = date.toISOString().split('T')[0];
          const newArray = data.meetingAttachments.map(item => (
            item.reportId > 0 ?
              {
                name: item.name != undefined && item.name != "" ? item.name : "",
                key: item.key != undefined && item.key != "" ? item.key : "",
                attachment: item.attachment != undefined && item.attachment != "" ? item.attachment : ""
              } : ''
          ));
          setopenmodelvedio(true);
          setVideoThumbnail(data.videoThumbnail);
          setOptionStatevalue(newArray);
          setDate(formattedDate);
          setStartTime(data.startTime)
          setEndTime(data.endTime)
          setSelectedCountry(data.location)
          setAddress(data.location)
          setSendNoti(data.notification)
          setText(data.notes)
          setAddreportModelId(data.reportId)
          setVideoLink(data.videoLink)
          let TagsList = [];
          if (data.guest.length > 0) {
            for (let i = 0; i < data.guest.length; i++) {
              TagsList.push(data.guest[i].name);
            }
          }
          setTags(TagsList);
          console.log("data ", data)
          setSubTitle(data.subTitle)
          // setOptionStatevalue()
          setEnabled(true);
          setHoldPage(false);
          setIsUpdate(true);
        } else {
          setHoldPage(false);
        }
      }
    }
  };

  // Handling Upload file and their fields
  const handleFileChange = async ({ e, i }) => {
    const fileList = event.target.files[0];
    let previousimage = e.target.name;
    if (previousimage != '' && previousimage != null && previousimage != undefined) {
      try {
        let url = BASE_URL + APIS_ENDPOINT.uploadImageFile;
        let token = APIS_ENDPOINT.bearerToken;
        let data = {
          "key": previousimage
        }
        let createMeetingData = await DELETE_DATA(url, data, token);
        console.log("createMeetingData: ", createMeetingData);
      } catch (error) {
        console.log("error: ", error);
      }
    }
    let url = BASE_URL + APIS_ENDPOINT.uploadImageFile;
    let token = APIS_ENDPOINT.bearerToken;
    let formData = new FormData();
    formData.append('file', fileList);
    try {
      let createMeetingData = await POST_DATA_FORMDATA(url, formData, token)
      // console.log("createMeetingData: ", createMeetingData);
      if (createMeetingData.key != undefined && createMeetingData.key != null) {
        e.target.name = createMeetingData.key;
        // let attachmentObject = {
        //   attachment: createMeetingData.attachment,
        //   key:createMeetingData.key,
        //   name:[...subTitle[i]]
        // }
        let CurrentElementData = [...optionStatevalue[i].key];
        let CurrentElementat = [...optionStatevalue[i].attachment];
        let ValueUpdatekey = [...CurrentElementData, createMeetingData.key];
        let ValueUpdateattachment = [...CurrentElementat, createMeetingData.attachment];
        let arr = [...optionStatevalue];
        arr[i].key = ValueUpdatekey[0];
        arr[i].attachment = ValueUpdateattachment[0];
        // setFilesArray(attachmentObject)
        setOptionStatevalue(arr);
      }
    } catch (error) {
      e.target.name = '';
    }
  };

  // Logic for saving the meeting
  const handleSave = async () => {
    if (eventDate.length != 0) {
      eventDate.forEach(element => {
        if (element.meetingTitle == title) {
          return setMeetingTitle(true);
        } else {
          setMeetingTitle(false);
        }
      });
    }

    if (title == '' || title == null) {
      return setMeetingTitlenull(true);
    } else {
      setMeetingTitlenull(false);
    }

    if (date == "" || date == null) {
      return setSavedate(true);
    } else {
      setSavedate(false);
    }

    // if (startTime == null || startTime == '') {
    //   return setStartTimevalue(true);
    // }else{
    //   setStartTimevalue(false);
    // }

    // if (endTime == null || endTime == '') {
    //   return setEndTimevalue(true);
    // }else{
    //   setEndTimevalue(false);
    // }

    if (videoLink != '') {
      try {
        new URL(videoLink);
        setVideoLinkchek(false);
      } catch (error) {
        return setVideoLinkchek(true);
      }
    }
    try {
      let url = BASE_URL + APIS_ENDPOINT.createMeeting;
      let token = APIS_ENDPOINT.bearerToken;
      let obj = {};
      obj.title = title;
      obj.meetingDate = date;
      obj.startTime = startTime;
      obj.endTime = endTime;
      obj.location = selectedCountry;
      obj.notification = sendNoti;
      obj.notes = text;
      obj.reportId = addreportModelId;
      obj.videoLink = videoLink;
      obj.videoThumbnail = videoThumbnail;
      obj.guests = tags;
      obj.subTitle = subTitle;
      obj.attachments = optionStatevalue;
      // let formData = new FormData();
      // formData.append('title', title);
      // formData.append('meetingDate', date);
      // formData.append('startTime', startTime);
      // formData.append('endTime', endTime);
      // formData.append('location', selectedCountry?.name);
      // formData.append('notification', sendNoti);
      // formData.append('notes', text);
      // formData.append('reportId', addreportModelId);
      // formData.append('videoLink', videoLink);
      // formData.append('guests', JSON.stringify(tags));
      // formData.append('subTitle', subTitle);

      // formData.append('files', files);

      let createMeetingData = await POST_DATA(url, obj, token);
      if (createMeetingData != null && createMeetingData != undefined && createMeetingData != '') {
        await showSuccess("Meeting Created Successfully.").then(()=>{
          setTimeout(() => {
            handleCancel();
            eventDateFunc();
            setOptionStatevalue([{ attachment: '', key: '', name: '' }]);
          }, 2000);
        })
      }
    } catch (error) {
      showError(error.error);
    }
  };

  // Update Meeting Function
  const handleUpdate = async () => {
    if (title == '' || title == null) {
      return setMeetingTitlenull(true);
    } else {
      setMeetingTitlenull(false);
    }

    if (date == "" || date == null) {
      return setSavedate(true);
    } else {
      setSavedate(false);
    }

    // if (startTime == null || startTime == '') {
    //   return setStartTimevalue(true);
    // }else{
    //   setStartTimevalue(false);
    // }

    // if (endTime == null || endTime == '') {
    //   return setEndTimevalue(true);
    // }else{
    //   setEndTimevalue(false);
    // }

    if (videoLink != '') {
      try {
        new URL(videoLink);
        setVideoLinkchek(false);
      } catch (error) {
        return setVideoLinkchek(true);
      }
    }

    try {
      let url = BASE_URL + APIS_ENDPOINT.createMeeting + editmeeting[0].id;
      let token = APIS_ENDPOINT.bearerToken;
      let obj = {};
      obj.title = title;
      obj.meetingDate = date;
      obj.startTime = startTime;
      obj.endTime = endTime;
      obj.location = selectedCountry;
      obj.notification = sendNoti;
      obj.notes = text;
      obj.reportId = addreportModelId;
      obj.videoLink = videoLink;
      obj.videoThumbnail = videoThumbnail;
      obj.guests = tags;
      obj.subTitle = subTitle;
      obj.attachments = optionStatevalue;

      let createMeetingData = await PUT_DATA(url, obj, token);
      console.log("1", createMeetingData)
      if (createMeetingData != null && createMeetingData != undefined && createMeetingData != '') {
        await showSuccess("Meeting Update Successfully").then(()=>{
          setTimeout(() => {
            handleCancel();
            eventDateFunc();
          }, 2000);
        })
      }
    } catch (error) {
      showError(error.error)
      // console.log("error: ", error);
    }
  }

  // Meeting Details based on title
  const editMeetingData = async (name) => {
    let url = BASE_URL + APIS_ENDPOINT.searchmeetingtitle + name;
    let token = APIS_ENDPOINT.bearerToken;
    let editMeetingData = await GAT_DATA(url, token);
    return editMeetingData;
    // console.log("reportData: ", reportData);
  }

  // Meeting Search
  const onSearchMeeting = async (e) => { // on search  Field Data
    // let url = BASE_URL + APIS_ENDPOINT.searchmeetingtitle +'Metting-1';
    // let token = APIS_ENDPOINT.bearerToken;
    // let reportData = await GAT_DATA(url,token);
    // console.log("reportData: ", reportData);
    const query = e.target.value;
    const filtered = eventDate.filter((event) =>
      event.title.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredEvents(filtered);
  }
  const toast = useRef(null);
  const toastSucess = useRef(null);

  // Close Meeting slider on close
  const handleCloseMeetingPage = () => {
    setEnabled(false);
    setSelectedMeeting({});
    setOptionStatevalue([{ attachment: '', key: '', name: '' }]);
    setTitle('');
    setDate(null);
    setStartTime(null);
    setEndTime(null);
    setSelectedCountry(null);
    setAddress('')
    setSendNoti(stubFalse);
    setText('');
    setAddreportModelId('');
    setVideoLink('');
    setTags([]);
    setSubTitle('');
    setIsUpdate(false);
    setAddreportModeltitle('');
  };

  // Close Meeting slider on outside clicking
  const handleCancel = () => {
    setEnabled(false);
    setSelectedMeeting({});
    setOptionStatevalue([{ attachment: '', key: '', name: '' }]);
    setTitle('')
    setDate(null)
    setStartTime(null)
    setEndTime(null)
    setSelectedCountry(null)
    setAddress('')
    setSendNoti(false)
    setText('')
    setAddreportModelId('')
    setVideoLink('')
    setTags([]);
    setSubTitle('');
    setAddreportModeltitle('')
    setIsUpdate(false);
  };

  const handleTextChange = (e) => {
    setText(e.htmlValue);
  };

  // Email Validation
  const ValidateEmail = (mail) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return (true)
    } else {
      return (false)
    }
  }

  //  Enter In guest List
  const hendleSeachEnter = (e) => {
    // console.log(e);
    if (e.key == 'Enter') {
      setAddGuest('');
      let checkValidTag = ValidateEmail(e.target.value);
      let checkAlreadyexits = tags.filter((x) => x == e.target.value);
      if (e.target.value != '' && checkValidTag && checkAlreadyexits.length == 0) {
        setTags([...tags, e.target.value]);
      }
    }
  }

  // Video open input
  const openvediomodel = () => {
    if (openmodelvedio == false) {
      setopenmodelvedio(true)
    }
    else {
      setopenmodelvedio(false)
    }
  }

  // Remove Guests from Array
  const removeTag = useCallback(
    (tag) => () => {
      setTags((previousTags) =>
        previousTags.filter((previousTag) => previousTag !== tag),
      );
    },
    [],
  );

  // EJS for Guest
  const tagMarkup = tags.map((value) => (
    <>
      <Tag>
        <div className="flex align-items-center gap-2">
          <span className="text-base">{value}</span>
          <span onClick={removeTag(value)} style={{ top: '2px', position: 'relative', left: '4px', color: 'black', cursor: 'pointer' }}>X</span>
        </div>
      </Tag>
    </>
  ));

  // Report Funtion
  const AddBoardFunc = async () => {
    setVisible(true);
    try {
      let url = BASE_URL + APIS_ENDPOINT.gusdreport;
      let token = APIS_ENDPOINT.bearerToken;
      let reportData = await GAT_DATA(url, token);
      let statusReport = reportData.filter((item) => item.status == "Approved")
      setAddreportModel(statusReport);
    } catch (error) {
      console.log("error: ", error);
    }
  }

  // Report Card Funtion
  const reportCardFun = (item) => {
    let { id, name } = item;
    setAddreportModelId(id);
    setAddreportModeltitle(name)
    setVisible(false);
  }
  //#endregion

  // Attachment update
  const AddmoreSubtitle = () => {
    setOptionStatevalue([...optionStatevalue, { attachment: '', key: '', name: '' }]);
  }

  // Remove Attachments
  const removeimage = async (i) => {
    let fileInput = document.getElementById('fileInput' + i);
    try {
      let url = BASE_URL + APIS_ENDPOINT.uploadImageFile;
      let token = APIS_ENDPOINT.bearerToken;
      let data = {
        "key": fileInput.name
      }
      let createMeetingData = await DELETE_DATA(url, data, token);
      if (createMeetingData.message) {
        fileInput.value = '';
        let data = [...optionStatevalue];
        data.splice(i, 1)
        setOptionStatevalue(data)
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }

  // Remove Video Image thumbnail
  const addremoveimage = async () => {
    let fileInput = document.getElementById('addtagimg');
    try {
      let url = BASE_URL + APIS_ENDPOINT.uploadImageFile;
      let token = APIS_ENDPOINT.bearerToken;
      let data = {
        "key": fileInput.name
      }
      let createMeetingData = await DELETE_DATA(url, data, token);
      if (createMeetingData.message) {
        fileInput.value = '';
        setVideoThumbnail('');
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }

  const backHandler = () => {
    navigate(`/${DASHBOARD}`);
  }

  // Adding video thumbnail
  const handleBackground = async (event) => {
    const fileList = event.target.files[0];
    let url = BASE_URL + APIS_ENDPOINT.uploadImageFile;
    let token = APIS_ENDPOINT.bearerToken;
    let formData = new FormData();
    formData.append('file', fileList);
    try {
      let createMeetingData = await POST_DATA_FORMDATA(url, formData, token)
      if (createMeetingData.key != undefined && createMeetingData.key != null) {
        event.target.name = createMeetingData.key;
        setVideoThumbnail(createMeetingData.key);
      }
    } catch (error) {
      event.target.name = '';
      setVideoThumbnail('');
    }
  }

  // Handle Sub title
  const handlersetSubTitle = ({ e, i }) => {
    let namevalue = e.target.value;
    let CurrentElementData = [...optionStatevalue[i].name];
    let ValueUpdatekey = [...CurrentElementData, namevalue];
    let arr = [...optionStatevalue];
    arr[i].name = ValueUpdatekey[ValueUpdatekey.length - 1];
    // setFilesArray(attachmentObject)
    setOptionStatevalue(arr);
  }

  const setUpStartTime = (e) => {
    setStartTime(e)
    let endTime = moment(e, 'HH:mm').add(15, 'minutes').format('HH:mm');
    setEndTime(endTime)
  }

  return (
    <>
      {holdPage ?
        (<div className="loading__">
          <ProgressSpinner />
        </div>) : null}
      <Container>
        <div className='meeting_report_file_heading_box'>
          <p onClick={() => backHandler()} style={{ cursor: 'pointer' }} className='report-name-second' ><BsArrowLeft size={20} /> Back to Home Page</p>
          {/* <p className='report-date'>{reportMeetingName}</p> */}
        </div>
        <div className='report-section-container'>
          {/* <p onClick={()=>backHandler()} style={{cursor:'pointer'}} className='report-name-second' ><BsArrowLeft size={20} /> Back to Home Page</p> */}
          <div className="custom-header">
            <div style={{ marginLeft: 120 }} className="p-input-icon-left">
              <SearchNormal size="20" />
              <InputText placeholder="Search by Days or Meeting No." onChange={(e) => onSearchMeeting(e)} />
            </div>
            <button onClick={handleCreateMeeting} className="btn btn-primary create-meeting"><Add size={22} />Create New Meeting</button>
          </div>
          <FullCalendar
            editable
            selectable
            events={filteredEvents.length > 0 ? filteredEvents : eventDate}
            contentHeight={670}
            select={handleToggle}
            eventClick={handleEventClick}
            headerToolbar={{
              start: `prev title next`,
              end: ""
            }}
            // eventContent={(info) => <Meetingpage info={info} />}
            plugins={[daygridPlugin, interactionPlugin]}
            views={["dayGridMonth", "dayGridWeek", "dayGridDay"]}
          />
        </div>
      </Container>

      {/* Slider Commponent */}
      {enabled ? (
        <>
          <div className="main-container">
            <Sidebar visible={enabled} onHide={handleCloseMeetingPage} position="right" className="meeting-Sidebar" style={{ width: '500px' }}>
              <div className="meeting-content">
                <div className="meeting-header">
                  <h6>Add New Meeting</h6>
                  <div className="meeting-buttons">
                    <Button label="Cancel" onClick={handleCancel} className="button-text-link" />
                    {!isUpdate && (
                      <Button label="Save" style={{ width: '112px' }} onClick={handleSave} className="btn btn-primary-md" />
                    )}
                    {isUpdate && (
                      <Button label="Update" onClick={handleUpdate} className="btn btn-primary-md" />
                    )}
                  </div>
                </div>
                <div className="metting_addLocation  form-group">
                  <label className="input-label">Add Title <span style={{ color: "red" }}>*</span></label>
                  <span className="p-input-left">
                    <InputText value={title} onChange={(e) => { setTitle(e.target.value); }} placeholder="Enter the title of Meeting" style={{ width: '100%' }} />
                    {meetingTitlenull ? <span style={{ color: "red" }}>Meeting Title can not be Empty</span> : null}
                    {meetingTitle ? <span style={{ color: "red" }}>Can not Create Meeting with same name</span> : null}
                  </span>
                </div>
                <div className="meeting-fields form-group">
                  <div className="d-flex md:flex-row justify-content-between  meeting_gapBetween">
                    <div className='agenda_cover_box_item_one'>
                      <label className="input-label">
                        Date <span style={{ color: "red" }}>*</span>
                      </label>
                      <Form.Control value={date} onChange={(e) => setDate(e.target.value)} type="date" placeholder="05/10/2023" />
                      {savedate ? <span style={{ color: "red" }}>please Fill Date</span> : null}
                    </div>
                    <div className="flex-auto">
                      <label className="input-label">
                        Start Time
                      </label>
                      <input id="start-time" className="form-control" value={startTime} onChange={(e) => setUpStartTime(e.target.value)} type="time" name="start-time" />
                      {/* {startTimevalue?<span style={{color:"red"}}>please Fill  Start Time</span>:null} */}
                    </div>
                    <div className="flex-auto">
                      <label className="input-label">
                        End Time
                      </label>
                      <input id="end-time" className="form-control" value={endTime} onChange={(e) => setEndTime(e.target.value)} type="time" name="end-time" />
                      {/* {endTimevalue ? <span style={{color:"red"}}>please Fill  End Time</span> :null} */}
                    </div>
                  </div>
                </div>

                {/* <div className="d-flex justify-content-end my-3">
                  <Checkbox className="mx-2" onChange={e => setAllChecked(e.checked)} checked={allChecked}></Checkbox>
                  <label htmlFor="ingredient1" > All Day</label>
                </div> */}
                <hr ></hr>
                <div className="form-group">
                  <label className="input-label">Add Location</label>
                  <span className="p-input-left">
                    <LoadScript googleMapsApiKey="AIzaSyDhfZdO6TuQiFXc52mlZ-roGAVNFknLfv0&libraries=places" libraries={['places']}>
                      <Autocomplete onLoad={handleLoad} onPlaceChanged={handleSelect}>
                        <div>
                          <input
                            type="text" className="p-inputtext p-component"
                            placeholder="Enter address..."
                            value={address}
                            onChange={handleInputChange}
                          />
                          {suggestions.length > 0 && (
                            <>
                              {suggestions.map((suggestion) => (
                                <p key={suggestion} onClick={() => handleSelect(suggestion)}>
                                  <SiGooglemaps /> {suggestion}
                                </p>
                              ))}
                            </>
                          )}
                        </div>
                      </Autocomplete>
                    </LoadScript>
                    {/* <AutoComplete className="w-100" field="name" value={selectedCountry} suggestions={filteredCountries} completeMethod={search} onChange={(e) => setSelectedCountry(e.value)} /> */}
                  </span>
                </div>

                {/* <div className="d-flex justify-content-end my-3">
                  <Checkbox className="mx-2" onChange={e => setSendNoti(e.checked)} checked={sendNoti}></Checkbox>
                  <label htmlFor="ingredient1">Send Notification</label>
                </div> */}

                <div className="">
                  <label className="input-label">
                    Create meeting Notes
                  </label>
                  <Editor value={text} onTextChange={(e) => { handleTextChange(e) }} style={{ height: '96px' }} />
                  {/* <div className="p-d-flex p-mt-2">
                <button className="p-button p-button-danger p-mr-2" onClick={handleClearText}>Clear</button>
                <button className="p-button p-button-primary" onClick={handlePrintText}>Print</button>
            </div> */}
                </div>

                <div className="my-3 report-list-modal">
                  {addreportModeltitle != '' ? <p className="attachmentvalue">{addreportModeltitle} <span onClick={() => setAddreportModeltitle("")} style={{ position: 'relative', left: '4px', color: 'red', cursor: 'pointer' }}>X</span></p> : null}
                  <Button className='btn btn-dark-ghost' icon="pi pi-external-link" onClick={() => AddBoardFunc()}><AttachSquare size="24" />  Add Board Report Attachment Text</Button>
                  <Dialog header="Report List" visible={visible} style={{ maxWidth: '600', textAlign: 'center' }} draggable={false} onHide={() => setVisible(false)}>
                    <p className="m-0">
                      {addreportModel.map((item, i) => {
                        return (
                          <div key={i}>
                            <Card title={item.name} subTitle="" className="md:w-25rem" style={{ cursor: 'pointer' }} onClick={() => reportCardFun(item)}>
                            </Card>
                          </div>
                        )
                      })}
                    </p>
                  </Dialog>
                </div>

                <div className="my-3">
                  <div className='btn btn-dark-ghost' onClick={openvediomodel}>
                    <span className=''><Video size="24" /></span><span>Add Video</span>
                  </div>
                  {openmodelvedio == true ? <>
                    <InputText value={videoLink} onChange={(e) => { setVideoLink(e.target.value); }} placeholder="Add Video Link" style={{ width: '100%', marginTop: "10px" }} />
                    <br/>
                    {videoLink != '' ?
                      <div className="upload_videofile">
                        {videoThumbnail != "" && videoThumbnail != undefined ? <span style={{ fontSize: "10px" }}>{videoThumbnail}</span> : null}
                        <label className="mt-3"> Select File<input type="file" id="addtagimg" name={videoThumbnail} accept="image/*" onChange={(e) => handleBackground(e)} />
                        </label><span style={{ cursor: "pointer", color: 'red', display: 'flex' }} onClick={() => addremoveimage()}><CloseSquare size={20} /></span>
                        </div> : null}
                    {/* <InputText value={videoLink} type="file" onChange={(e) => { setVideoLink(e.target.value); }} placeholder="Add Video Link" style={{ width: '100%', marginTop: "10px" }} /> */}
                  </>
                    :
                    ""}
                </div>
                {videoLinkchek ? <span style={{ color: "red" }}>Please enter a valid URL</span> : null}
                <hr ></hr>
                <div className="metting_addLocation  form-group">
                  <label className="input-label">Add Guests</label>
                  <span className="p-input-icon-left align-items-center">
                    <SearchNormal size="20" />
                    <InputText value={addGuest} placeholder="Add Guests" onChange={(e) => { setAddGuest(e.target.value) }} onKeyPress={(e) => { hendleSeachEnter(e) }} style={{ width: '100%' }} />
                  </span>
                </div>
                {tagMarkup}
                {optionStatevalue.map((item, i) => (
                  <>
                  <div className="p-1 mb-3" style={{border: '#ccc dashed 1px'}}>
                    <div className="metting_addLocation  form-group">
                      <label className="input-label">Add Sub-title</label>
                      <span className="p-input-icon-left" key={i} >
                        <Minus size="20" />
                        <InputText value={item.name} onChange={(e) => handlersetSubTitle({ e, i })} placeholder="" style={{ width: '100%' }} />
                      </span>
                    </div>
                    <div className="upload_videofile" key={i} >
                      {item.key != null && item.key != "" ? <span style={{ fontSize: "10px" }}>{item.key}</span> : null}
                      <label>Select File<input className="btn-dark-ghost-rounded" type="file" id={'fileInput' + i} name={item.key} onChange={(e) => handleFileChange({ e, i })} /></label>
                    </div>
                    <span style={{ cursor: "pointer", color: 'red', display: 'flex', justifyContent: 'end' }} onClick={() => removeimage(i)}><CloseSquare size={20} /></span>
                    </div>
                  </>
                ))}
                <div className="btn-add-more-rounded my-4">
                  <Button label="Add more Sub-titles" outlined onClick={() => AddmoreSubtitle()} />
                </div>
              </div>
              <Toast ref={toast} />
              <Toast ref={toastSucess} position="bottom-left" />
            </Sidebar>
          </div></>
      ) : null}
    </>
  );
};

export default MyCalendar;