

function ConvertResponseForSelect(responses, defaultArray = []) {
    var myArray = [];
    if (!Array.isArray(defaultArray)) { myArray.push(defaultArray); }
    var objectArray = Object.entries(responses);
    objectArray.forEach(([key, value]) => {
        myArray.push({ name: value.name, code: value.id });
    })
    return myArray;
}

export {
   ConvertResponseForSelect,
};
