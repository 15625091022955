import React, { useRef, useState, useEffect } from "react";
import logo from "../../assets/images/GUSD_logo.png";
import iconNotification from "../../assets/images/icon_notification.svg";
import { FiLogOut } from "react-icons/fi";
// import { FiLock, FiUser, FiToggleRight } from "react-icons/fi";
// import { VscGear } from "react-icons/vsc";
// import { MdOutlineLightMode } from "react-icons/md"
import { OverlayPanel } from 'primereact/overlaypanel';
import { Container, Card, Row, Col, Button, Navbar, Nav, NavbarBrand, NavLink, NavItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { CREATE_MEETING, CREATE_REPORT, NOTIFICATIONS, USER_MANAGEMENT_ADMIN } from "../../global/PageNames";
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
// import { useNavigate } from 'react-router';
import { POST_DATA } from "../../global/apiType";
import { APIS_ENDPOINT, BASE_URL } from "../../global/server";

const Header = () => {
    const [userData, setUserData] = useState('');
    const [userNotification, setUserNotifications] = useState([]);
    const UserPermissions = JSON.parse(localStorage.getItem('UserPermissions'));
    useEffect(() => {
        let item = JSON.parse(localStorage.getItem('GUSD-user-Google-token'));
        if (item.value) {
            let data = jwt_decode(item.value);
            data = JSON.stringify(data);
            data = JSON.parse(data);
            setUserData(data)
            getUserNotifications()
        }
    }, []);

    var getUserNotifications = async () => {
        const UserId = JSON.parse(localStorage.getItem('userId'));
        let token = APIS_ENDPOINT.bearerToken;
        let url = BASE_URL + APIS_ENDPOINT.userNotification + UserId.value;
        let data = { "start": 0, "limit": 2 }
        const response = await POST_DATA(url, data, token);
        if (response.rows) { setUserNotifications(response.rows) }
    }

    function handleLogOut() {
        let userToken = localStorage.getItem('GUSD-user-token') == null ? false : true;
        let userGoogleToken = localStorage.getItem('GUSD-user-Google-token') == null ? false : true;
        if (userToken) {
            localStorage.removeItem('GUSD-user-token');
        }
        if (userGoogleToken) {
            localStorage.removeItem('GUSD-user-Google-token');
        }
        window.location.reload();
        window.location = '/'
    }
    const popOver = useRef(null);
    const [isToggled, setIsToggled] = useState(false);

    const handleToggle = () => {
        setIsToggled(!isToggled);
    };
    return (
        <header className="header-bg">
            <Navbar fixed="top" expand="xs" className="" style={{ height: 120 }}>
                <Container>
                    <Row noGutters className="position-relative w-100">
                        <Col className="d-none d-lg-flex justify-content-between align-items-center">
                            <NavbarBrand className="font-weight-bold d-flex align-items-center" href="/">
                                <img src={logo} alt="avatar" className="img-fluid rounded-circle" style={{ width: 75 }} />
                                <div className="logo-text-cnt">
                                    <span className="logo-text-1">GUSD Board of Education</span>
                                    <span className="logo-text-2">Schedule & Agenda Administration</span>
                                </div>
                            </NavbarBrand>
                            <Nav className="mrx-auto" navbar>
                                <NavItem className="d-flex align-items-center"></NavItem>
                                {
                                    UserPermissions.value["Create New Report"] === 1 &&
                                    <NavItem className="d-flex align-items-center">
                                        <NavLink className="font-weight-bold" href={`/${CREATE_REPORT}`}>
                                            Reports
                                        </NavLink>
                                    </NavItem>
                                }
                                {
                                    UserPermissions.value["Update Calender"] === 1 &&
                                    <NavItem className="d-flex align-items-center">
                                        <NavLink className="font-weight-bold" href={`/${CREATE_MEETING}`}>
                                            Meetings
                                        </NavLink>
                                    </NavItem>
                                }
                                {
                                    UserPermissions.value["User Management"] === 1 &&
                                    <NavItem className="d-flex align-items-center">
                                        <NavLink className="font-weight-bold" href={`/${USER_MANAGEMENT_ADMIN}`}>
                                            User Management
                                        </NavLink>
                                    </NavItem>
                                }

                                {/* <NavItem className="d-flex align-items-center">
                                    <NavLink className="font-weight-bold" href="/">
                                        Templates
                                    </NavLink>
                                </NavItem>
                                <NavItem className="d-flex align-items-center">
                                    <NavLink className="font-weight-bold" href="">
                                        Setting
                                    </NavLink>
                                </NavItem> */}

                                <NavItem>
                                    <Button type="button" className="d-flex align-items-center rounded-pill btn-add" color="primary" outline onClick={(e) => popOver.current.toggle(e)}>
                                        <span>+</span> Add
                                    </Button>
                                    <OverlayPanel className="add-template" ref={popOver}>
                                        <ul>
                                            {
                                                UserPermissions.value["Schedule a New Meeting"] === 1 &&
                                                <li><a href={`/${CREATE_MEETING}`}>Schedule a New Meeting</a></li>
                                            }
                                            {
                                                UserPermissions.value["Create New Report"] === 1 &&
                                                <li><a href={`/${CREATE_REPORT}`}>Create a New Report</a></li>
                                            }
                                            {/* <li><a href="">Create a New Template</a></li> */}
                                        </ul>
                                    </OverlayPanel>
                                </NavItem>
                                <NavItem className="d-flex align-items-center">
                                    <NavLink className="font-weight-bold" onClick={handleToggle}>
                                        {
                                            userNotification.length > 0 &&
                                            <div className="icon mx-0" id="bell"><div className="counts"></div><img src={iconNotification} alt="" /> </div>
                                        }
                                    </NavLink>
                                    <Card className={isToggled ? 'notifications toggled-hide' : 'notifications'}>
                                        <div id="box">
                                            {
                                                userNotification.map((item, i) => {
                                                    return (
                                                        <div key={i.toString()} className="notifications-item">
                                                            <div className="text">
                                                                <h4>{item.notification_title}</h4>
                                                                <p>{item.notification_description}</p>
                                                            </div>
                                                            <NavLink className="btn btn-primary notificatoin-act" href={`/${NOTIFICATIONS}`}>
                                                                Open
                                                            </NavLink>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </Card>
                                </NavItem>
                                <UncontrolledDropdown className="p-relative user-profile" nav inNavbar>
                                    <DropdownToggle className="font-weight-bold" nav caret>
                                        <img className="thumbnail-image" src={userData?.picture} style={{ width: 48, borderRadius: "100%" }} alt="user pic" />
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-lg-end" data-bs-popper="static">
                                        <DropdownItem className="" header disabled>
                                            {/* <span className="d-block">Super Admin</span> */}
                                            {userData?.name}
                                        </DropdownItem>
                                        {/* <DropdownItem><FiUser /> View profile</DropdownItem>
                                        <DropdownItem><VscGear /> Settings</DropdownItem>
                                        <DropdownItem><FiLock /> Change Password</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem><FiToggleRight /> Switch Account</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem><MdOutlineLightMode /> Switch to Dark Mode</DropdownItem> */}
                                        <DropdownItem divider />
                                        <DropdownItem onClick={() => handleLogOut()}><FiLogOut /> Logout</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                                {/* <Nav pullRight></Nav> */}
                            </Nav>
                        </Col>
                    </Row>
                </Container>
            </Navbar>
        </header>
    );
};

export default Header;
