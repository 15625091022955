import React from 'react';
import MyCalendar from './meetingCalender/MyCalendar'

const CreateNewMeetingPage = () => {

  return (
    <React.Fragment>
      <MyCalendar />
    </React.Fragment>
  )
}

export default CreateNewMeetingPage
