export const CREATE_REPORT = 'CreateReport';
export const TEMPLATE_PAGE = "TemplatePage";
export const DASHBOARD = "Dashboard"
export const REPORT_SECTION ="ReportSection"
export const CREATE_MEETING = "CreateNewMeetingPage"
export const MEETING_DETAILS = "MeetingDetailsPage"
export const REPORTMEETING_DETAILS = "ReportMeetingDetailsPage"
export const LOGIN = 'SignIn';
export const NOTIFICATIONS = "notifications";
export const COPY_EXISTING_REPORT = "CopyExistingReport";

//Admin
export const ADMINDASHBOARD = "Admin/Dashboard"
export const CREATE_MEETING_FOR_ADMIN = "Admin/CreateNewMeetingPage"
export const REPORT_SECTION_FOR_ADMIN = "Admin/ReportSection"
export const CREATE_REPORT_FOR_ADMIN = 'Admin/CreateReport';
export const MEETING_DETAILS_FOR_ADMIN = "Admin/MeetingDetailsPage"
export const REPORTMEETING_DETAILS_FOR_ADMIN = "Admin/ReportMeetingDetailsPage"
export const VIEW_NOTIFICATIONS_FOR_ADMIN = "Admin/MeetingDetailsPage"
export const USER_MANAGEMENT_ADMIN = "admin/usermanagement"
export const USER_PERMISSION = "admin/userpermission"
export const ROLES_MANAGEMENT_ADMIN = "admin/usermanagement/roles"
export const ROLES_PERMISSION_MANAGEMENT_ADMIN = "admin/usermanagement/rolespermissions"
