import React, { createContext,useState} from "react";
import PropTypes from 'prop-types';
export const AuthContext = createContext();


export const ContextProvider = ({ children }) => {
const [reportSectionDetails, setReportSectionDetails] = useState([]);
const [reportCurrentSectionDetails, setReportCurrentSectionDetails] = useState({});
const [reportMeetingDetailsPage, setReportMeetingDetailsPage] = useState({});

return <AuthContext.Provider value={{ reportSectionDetails , setReportSectionDetails,setReportCurrentSectionDetails,reportCurrentSectionDetails,setReportMeetingDetailsPage,reportMeetingDetailsPage }}>{children}</AuthContext.Provider>;
};

ContextProvider.propTypes = {
    children: PropTypes.node,
  };