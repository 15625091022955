import React from 'react'
import Header from './Header';
import Footer from './Footer';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { PageNames } from '../../global';

const Layout = ({ children }) => {
  const location = useLocation();
  let userData = localStorage.getItem('GUSD-user-token') == null ? false : true;
  // Define an array of routes where the header should not be displayed
  const excludedRoutes = [PageNames.LOGIN];
  const shouldDisplayHeader = !excludedRoutes.includes(location.pathname);


  // Define an array of routes where the header should  displayed
  const IsShowFooter = [PageNames.DASHBOARD,'Dashboard',"/Dashboard",'*'];
  const shouldDisplayFooter = IsShowFooter.includes(location.pathname);
  let showHeader = shouldDisplayHeader ?? true;
  let showFooter = shouldDisplayFooter ?? true
  return (
    <React.Fragment>
      {showHeader && userData ? <Header /> : null}
      {children}
      {showFooter ? <Footer /> : null}
    </React.Fragment>
  )
};
Layout.propTypes = {
  children: PropTypes.node,
  isFooterShow: PropTypes.bool,
  isHeaderShow: PropTypes.bool
};

export default Layout;