import React from "react";
import "./App.css";
import { useRoutes } from "react-router";
import { PageNames } from "./global";
import CreateReport from "./Pages/CreateReportPage/CreateReport";
import Dashboard from "./Pages/DashboardPage/Dashboard";
import SignIn from "./Pages/SignPage/SignIn";
import ReportSection from "./Pages/ReportSectionPage/ReportSection";
import CreateNewMeetingPage from "./Pages/ScheduleMeetingPage/CreateNewMeetingPage";
import Layout from "./components/Layout";
import MeetingDetails from "./Pages/MeetingDetailsPage";
import ReportMeetingAttachment from "./Pages/ReportMeetingAttachment/ReportMeetingAttachment";
import { ContextProvider } from "./components/ContextAPI/ContextAPI";
import Notifications from "./Pages/Notification/Notification";
import CopyExistingReport from "./Pages/CopyExistingReport/ReportSection";

//*Admin
import AdminLayout from "./components/AdminLayout";
import AdminSignIn from "./Pages/Admin/SignPage/AdminSignIn";
import AdminDashboard from "./Pages/Admin/DashboardPage/AdminDashboard";
import CreateNewMeetingPageForAdmin from "./Pages/Admin/ScheduleMeetingPage/CreateNewMeetingPageForAdmin";
import ReportSectionForAdmin from "./Pages/Admin/ReportSectionPage/ReportSection";
import CreateReportForAdmin from "./Pages/Admin/CreateReportPage/CreateReportForAdmin";
import ReportMeetingAttachmentForAdmin from "./Pages/Admin/ReportMeetingAttachment/ReportMeetingAttachmentForAdmin";
import AdminMeetingDetails from "./Pages/Admin/MeetingDetailsPage";
import AdminViewNotification from "./Pages/Admin/Notification/AdminViewNotification";
import Users from "./Pages/Admin/UserManagement/Users";
import UserPermission from "./Pages/Admin/UserManagement/UserPermission";
import Roles from "./Pages/Admin/UserManagement/Roles";
import RolePermissions from "./Pages/Admin/UserManagement/RolePermissions";

function App() {
  let userData = localStorage.getItem('GUSD-user-token') == null ? false : true;

  const routes = useRoutes([
    { path: '*', element: <Dashboard /> },
    // { path: '/', element: <SignIn /> },
    { path: PageNames.DASHBOARD, element: <Dashboard /> },
    { path: PageNames.CREATE_REPORT, element: <CreateReport /> },
    { path: PageNames.REPORT_SECTION, element: <ReportSection /> },
    { path: PageNames.CREATE_MEETING, element: <CreateNewMeetingPage /> },
    { path: PageNames.MEETING_DETAILS +'/:id', element: <MeetingDetails /> },
    { path: PageNames.REPORTMEETING_DETAILS +'/:id', element: <ReportMeetingAttachment /> },
    { path: PageNames.COPY_EXISTING_REPORT, element: <CopyExistingReport /> },
    { path: PageNames.ADMINDASHBOARD, element: <AdminDashboard /> },
    { path: PageNames.CREATE_MEETING_FOR_ADMIN, element: <CreateNewMeetingPageForAdmin /> },
    { path: PageNames.REPORT_SECTION_FOR_ADMIN, element: <ReportSectionForAdmin /> },
    { path: PageNames.CREATE_MEETING_FOR_ADMIN, element: <CreateReportForAdmin /> },
    { path: PageNames.REPORTMEETING_DETAILS_FOR_ADMIN + '/:id', element: <ReportMeetingAttachmentForAdmin /> },
    { path: PageNames.MEETING_DETAILS_FOR_ADMIN + '/:id', element: <AdminMeetingDetails /> },
    { path: PageNames.NOTIFICATIONS, element: <Notifications /> },
    { path: PageNames.VIEW_NOTIFICATIONS_FOR_ADMIN, element: <AdminViewNotification /> },
    { path: PageNames.USER_MANAGEMENT_ADMIN, element: <Users /> },
    { path: PageNames.USER_PERMISSION + "/:id", element: <UserPermission /> },
    { path: PageNames.ROLES_MANAGEMENT_ADMIN, element: <Roles /> },
    { path: PageNames.ROLES_PERMISSION_MANAGEMENT_ADMIN, element: <RolePermissions /> },
  ]);

  return (
    <React.Fragment>
      <ContextProvider>
        <Layout >
          {userData ? routes : <SignIn />}
        </Layout>
      </ContextProvider>
    </React.Fragment>
  );
}

export default App;
