import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function AdminViewNotification() {
    var myNavigation = useNavigate();
    //Notifications
    const [notificationList, setNotificationList] = useState([]);

    async function BindList() {
    }
    useEffect(() => {
        BindList();
    }, []);

    return (
        <div className="pt-16">
            <div className="dashboard-main-wrapper pl-16" >
                <div className="p-5">
                    <div className="sm:flex items-center ">
                        <div className="text-2xl font-semibold dark:text-white heading-label">Notifications</div>
                    </div>
                    <div className="notification-details mt-5">
                        <div className="notifications-item">
                            <div className="text">
                                <h4>Board Meeting No. 19 May 2, 2023</h4>
                                <p>2023, apr 23rd 12:23 pm 8750 Dorsett Dr. Huntington Beach, CA 92646 23 Participants</p>
                            </div>
                        </div>
                        <div className="notifications-item">
                            <div className="text">
                                <h4>Board Meeting No. 19 May 2, 2023</h4>
                                <p>2023, apr 23rd 12:23 pm 8750 Dorsett Dr. Huntington Beach, CA 92646 23 Participants</p>
                            </div>
                        </div>
                        <div className="notifications-item">
                            <div className="text">
                                <h4>Board Meeting No. 19 May 2, 2023</h4>
                                <p>2023, apr 23rd 12:23 pm 8750 Dorsett Dr. Huntington Beach, CA 92646 23 Participants</p>
                            </div>
                        </div>
                        <div className="notifications-item">
                            <div className="text">
                                <h4>Board Meeting No. 19 May 2, 2023</h4>
                                <p>2023, apr 23rd 12:23 pm 8750 Dorsett Dr. Huntington Beach, CA 92646 23 Participants</p>
                            </div>
                        </div>
                        <div className="notifications-item">
                            <div className="text">
                                <h4>Board Meeting No. 19 May 2, 2023</h4>
                                <p>2023, apr 23rd 12:23 pm 8750 Dorsett Dr. Huntington Beach, CA 92646 23 Participants</p>
                            </div>
                        </div>
                        <div className="notifications-item">
                            <div className="text">
                                <h4>Board Meeting No. 19 May 2, 2023</h4>
                                <p>2023, apr 23rd 12:23 pm 8750 Dorsett Dr. Huntington Beach, CA 92646 23 Participants</p>
                            </div>
                        </div>
                        <div className="notifications-item">
                            <div className="text">
                                <h4>Board Meeting No. 19 May 2, 2023</h4>
                                <p>2023, apr 23rd 12:23 pm 8750 Dorsett Dr. Huntington Beach, CA 92646 23 Participants</p>
                            </div>
                        </div>
                        <div className="notifications-item">
                            <div className="text">
                                <h4>Board Meeting No. 19 May 2, 2023</h4>
                                <p>2023, apr 23rd 12:23 pm 8750 Dorsett Dr. Huntington Beach, CA 92646 23 Participants</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}