import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import PropTypes from "prop-types";
import { APIS_ENDPOINT, BASE_URL } from "../../global/server";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function TitlePDFViewer({ id, comments }) {
  const [allPageNumbers, setAllPageNumbers] = useState('');
  function onDocumentLoadSuccess(pdf) {
    const allPageNumbers = [];
    for (let p = 1; p < pdf.numPages + 1; p++) { allPageNumbers.push(p); }
    setAllPageNumbers(allPageNumbers);
  }
  let url = BASE_URL + APIS_ENDPOINT.widgetTypePreview + id;
  console.log(url)
  return (
    <div id="container">
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
        {allPageNumbers ? allPageNumbers.map((pn) => (
          <Page
            key={pn}
            renderAnnotationLayer={false}
            pageNumber={pn}
          >
            {
              comments.map((item, i) => {
                return (
                  pn == item.page_no &&
                  <>
                    <div style={{ paddingBlock: 10, paddingInline: 25,marginInline:20, backgroundColor: "#cccccc" }} >
                      <label style={{ color: "#FFF", fontSize:18 }} key={i.toString()}>{item.comments} </label>
                      <br />
                    </div >
                  </>
                )
              })
            }
          </Page>
        )) : undefined}

      </Document>
    </div>
  );
}

TitlePDFViewer.propTypes = { id: PropTypes.string, comments: PropTypes.array };
export default TitlePDFViewer;