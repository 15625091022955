import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Sidebar } from 'primereact/sidebar';
import { Tag } from 'primereact/tag';
import React, { useEffect, useState } from 'react';
import { BsArrowLeft } from "react-icons/bs";
import { VscEdit, VscShield, VscTrash } from "react-icons/vsc";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container } from "reactstrap";
import { ADMINDASHBOARD, ROLES_MANAGEMENT_ADMIN, USER_PERMISSION } from '../../../global/PageNames';
import { DELETE_DATA, GAT_DATA, POST_DATA, PUT_DATA } from '../../../global/apiType';
import { ConvertResponseForSelect } from '../../../global/commonfunctions';
import { APIS_ENDPOINT, BASE_URL } from '../../../global/server';

function Users() {

    const navigate = useNavigate();
    const [isUpdate, setIsUpdate] = useState(false);

    const [createUserPopUp, setCreateUserPopUp] = useState(false);
    const [renderItems, setRenderItems] = useState(false);

    const [rolesList, setRolesList] = useState([]);
    const [selectedRole, setSelectedRole] = useState();

    //Save User
    const [userId, setUserId] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");

    const [usersList, setUsersList] = useState([]);


    useEffect(() => {
        getRoles()
        getUsers()
    }, []);

    const backHandler = () => {
        navigate(`/${ADMINDASHBOARD}`);
    }

    const actionsBodyTemplate = (user) => {
        return (
            <>
                <button className='border-0 bg-white' onClick={() => { navigate(`/${USER_PERMISSION + "/" + user.id}`) }}>
                    <VscShield size={20} style={{ marginRight: 15 }} />
                </button>

                <button className='border-0 bg-white' onClick={() => editUser(user)}>
                    <VscEdit size={20} style={{ marginRight: 15 }} />
                </button>

                <button className='border-0 bg-white' onClick={() => {
                    confirmDialog({
                        message: 'Do you want to delete this record?',
                        header: 'Delete Confirmation',
                        icon: 'pi pi-info-circle',
                        acceptClassName: 'p-button-danger',
                        accept: () => deleteUser(user)
                    });
                }} >
                    <VscTrash size={20} style={{ marginRight: 15 }} />
                </button>
            </>
        )
    };

    //STATUS
    const statusTemplate = (user) => {
        return <Tag value={user.active ? "Active" : "In-Active"} severity={getSeverity(user)}></Tag>;
    };


    const getSeverity = (user) => {
        switch (user.active) {
            case true:
                return 'success';

            default:
                return null;
        }
    };

    //DELETE USER
    const deleteUser = async (userDetails) => {
        let token = APIS_ENDPOINT.bearerToken;
        let url = BASE_URL + APIS_ENDPOINT.userList + "/" + userDetails.id;
        let data = {};
        const response = await DELETE_DATA(url, data, token);
        if (response) {
            clearAll()
            getUsers()
            toast.success("Deleted")
        }
    }

    //EDIT USER
    const editUser = (userDetails) => {
        if (userDetails.id) {
            setUserId(userDetails.id)
            if (userDetails.role_id) {
                let findRole = rolesList.find(e => e.code === userDetails.role_id)
                setSelectedRole({ name: findRole.name, code: findRole.code })
            } else {
                setSelectedRole("")
            }
            var name = userDetails.displayName.split(" ")
            if (name) {
                setUserFirstName(name[0])
                setUserLastName(name[1])
            }
            setUserEmail(userDetails.useremail)
            setIsUpdate(true)
            setCreateUserPopUp(true)
        }
    }

    //get ALl roles
    async function getRoles() {
        let url = BASE_URL + APIS_ENDPOINT.roleList;
        let item = JSON.parse(localStorage.getItem('GUSD-user-token'));
        let token = item?.value
        let rolesList = await GAT_DATA(url, token);
        if (rolesList) {
            var myArray = []; myArray = ConvertResponseForSelect(rolesList)
            setRolesList(myArray)
        }

    }

    const roleTemplate = (user) => {
        let findRole = rolesList.find(e => e.code === user.role_id)
        if (findRole) { return findRole.name }
    };


    const getUsers = async () => {
        let url = BASE_URL + APIS_ENDPOINT.userList;
        let item = JSON.parse(localStorage.getItem('GUSD-user-token'));
        let token = item?.value
        let rolesList = await GAT_DATA(url, token);
        if (rolesList) { setUsersList(rolesList) }
        setRenderItems(true)
    }

    const saveUser = async () => {
        var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
        var validateEmail = regexEmail.test(userEmail)
        if (selectedRole.code === "") { toast.error("Select Role"); return }
        if (userFirstName === "") { toast.error("Enter first name."); return }
        if (userLastName === "") { toast.error("Enter last name."); return }
        if (userEmail === "") { toast.error("Enter email."); return }
        if (!validateEmail) { toast.error("Invalid email."); return }

        if (userId) {
            let token = APIS_ENDPOINT.bearerToken;
            let url = BASE_URL + APIS_ENDPOINT.userList + "/" + userId;
            let data = { displayName: userFirstName + " " + userLastName, role_id: selectedRole.code };
            const response = await PUT_DATA(url, data, token);
            if (response) {
                clearAll(); getUsers(); toast.success("Updated.")
            }
        } else {
            let fullName = userFirstName.concat(" " + userLastName)
            let token = APIS_ENDPOINT.bearerToken;
            let url = BASE_URL + APIS_ENDPOINT.userList;
            let data = { displayName: fullName, role_id: selectedRole.code, useremail: userEmail };
            const response = await POST_DATA(url, data, token);
            if (response.id) {
                clearAll(); getUsers(); toast.success("Success.")
            }
        }
    }


    const clearAll = () => {
        setIsUpdate(false);
        setUserEmail("")
        setUserFirstName("")
        setUserLastName("")
        setSelectedRole("")
        setUserId("")
        setCreateUserPopUp(false)
    }

    return (
        < >
            <Container>
                <div className='mt-10'>
                    <div className='meeting_report_file_heading_box'>
                        <p onClick={() => backHandler()} style={{ cursor: 'pointer' }} className='report-name-second' ><BsArrowLeft size={20} /> Return to Home</p>
                    </div>
                </div>

                {
                    renderItems &&
                    <>
                        <div style={{ textAlign: "end", marginBottom: 20 }}>
                            <Button style={{ marginRight: 15, backgroundColor: '#113699' }} size='small' label="Create User" className='r-5' onClick={() => { clearAll(); setCreateUserPopUp(true) }} />
                            <Button style={{ backgroundColor: '#113699' }} size='small' label="Roles" onClick={() => navigate(`/${ROLES_MANAGEMENT_ADMIN}`)} />
                        </div>

                        <DataTable showGridlines value={usersList} paginator rows={50} rowsPerPageOptions={[50, 100, 150, 200]}>
                            <Column field="role" header="Role" body={roleTemplate}></Column>
                            <Column field="displayName" header="Name"></Column>
                            <Column field="useremail" header="Email"></Column>
                            <Column align={"center"} body={statusTemplate} header="Status"></Column>
                            <Column align={"center"} header="Actions" body={actionsBodyTemplate}></Column>
                        </DataTable>
                    </>
                }

                <>
                    <div style={{ textAlign: "center" }}>
                        {
                            !renderItems &&
                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                        }
                    </div>
                </>

                <Sidebar visible={createUserPopUp} position='right'
                    style={{ width: '50vw', height: "100%", position: "relative" }}
                    onHide={() => setCreateUserPopUp(false)}
                >
                    <div className="">
                        <div style={{ color: "#0a6dc2", fontSize: 20, fontWeight: "bold" }}>Create New User</div>
                        <div style={{ fontSize: 14, fontWeight: "bold" }}>User</div>
                    </div>


                    <div style={{ marginTop: 20 }}>
                        <div className='col-md-12'>
                            <label style={{ fontSize: 14 }} className='d-block'>Role</label>
                            <Dropdown
                                style={{ width: "100%", marginTop: 5 }}
                                options={rolesList}
                                value={selectedRole}
                                onChange={(e) => setSelectedRole(e.value)}
                                optionLabel="name"
                                editable placeholder="Select a role" className="w-full md:w-14rem" />
                        </div>

                        <div className='row' style={{ marginTop: 20 }}>
                            <div className='col-md-6'>
                                <label style={{ fontSize: 14, marginBottom: 5 }} className='d-block'>First Name</label>
                                <InputText style={{ width: "100%" }} className="p-inputtext-sm" value={userFirstName} onChange={(e) => setUserFirstName(e.target.value)} />
                            </div>

                            <div className='col-md-6'>
                                <label style={{ fontSize: 14, marginBottom: 5 }} className='d-block'>Last Name</label>
                                <InputText style={{ width: "100%" }} className="p-inputtext-sm" value={userLastName} onChange={(e) => setUserLastName(e.target.value)} />
                            </div>
                        </div>


                        <div className='col-md-12' style={{ marginTop: 20 }}>
                            <label style={{ fontSize: 14, marginBottom: 5 }} className='d-block'>Email</label>
                            <InputText disabled={isUpdate ? true : false} type='email' style={{ width: "100%" }} className="p-inputtext-sm" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} />
                        </div>


                        <div style={{ position: "absolute", bottom: 20, right: 20, margin: 10 }}>
                            <Button style={{ marginRight: 20 }} size='small' label="Cancel" severity="secondary" text raised onClick={() => setCreateUserPopUp(false)} />
                            <Button style={{ backgroundColor: '#113699' }} size='small' label={isUpdate ? "Update" : "Save"} onClick={() => saveUser()} />
                        </div>

                    </div>

                </Sidebar>

                <ConfirmDialog />

            </Container>
        </>
    )
}

export default Users;

