import React ,{ useEffect,useState,useRef }from 'react'
import { useParams } from 'react-router-dom';
import { BsArrowLeft, BsThreeDotsVertical } from "react-icons/bs";
import { APIS_ENDPOINT, BASE_URL } from '../../global/server';
import { GAT_DATA } from '../../global/apiType';
import { DocumentDownload, ArrowCircleDown2, AudioSquare } from 'iconsax-react';
import { useNavigate } from 'react-router';
import { DASHBOARD } from '../../global/PageNames';
import { Toast } from 'primereact/toast';
// import { Player } from 'video-react';
import AWS from 'aws-sdk';
const MeetingDetails = () => {
  const navigate = useNavigate();
  const toastBL = useRef(null);
  const { id } = useParams();
  const [reportMeetingName, setReportMeetingName] = useState('');
  const [reportMeetingAttechme, setReportMeetingAttechme] = useState([]);
  const [reportSuperintendents, setReportSuperintendents ] = useState([]);

  useEffect(() => {
    meetingPublishReport();
  }, []);

     // Check if the item is expired and remove it from local storage
     function checkItemExpiration() {
      const item = JSON.parse(localStorage.getItem('GUSD-user-token'));
      const itemGoogle = JSON.parse(localStorage.getItem('GUSD-user-Google-token'));
      if (item && new Date().getTime() > item?.expirationTime) {
        localStorage.removeItem('GUSD-user-token');
        window.location.reload();
        window.location = '/'
      }

      if (itemGoogle && new Date().getTime() > item?.expirationTime) {
        localStorage.removeItem('GUSD-user-Google-token');
        window.location.reload();
        window.location = '/'
      }
      }

    setInterval(checkItemExpiration, 600000);

  const GetAWSObjectURL = (filePath) =>{ // Downlaod  File Function
    let filename = filePath;
    const s3 = new AWS.S3({
      accessKeyId: process.env.REACT_APP_AWS_AccessKeyId,
      secretAccessKey: process.env.REACT_APP_AWS_SecretAccessKey,
      signatureVersion: 'v4',
      region: "us-east-2",
    });

    const signedUrlExpireSeconds = 60 * 10000
    const downloadURL = s3.getSignedUrl('getObject', {
        Bucket: "gusdreportbuilder",
        Key: filePath,
        Expires: signedUrlExpireSeconds
    })
    try {
        fetch(downloadURL)
            .then(response => {
                const disposition = response.headers.get('Content-Disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    filename = disposition.split('filename=')[1];
                }
                return response.blob().then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                });
            })
            .catch((error) => {
              toastBL.current.show({ severity: 'warn', summary: 'error', detail: error, life: 3000 });
            });
        } catch (error) {
        toastBL.current.show({ severity: 'warn', summary: 'error', detail: 'Error downloading file:', life: 3000 });
    }
};


  const meetingPublishReport = async () =>{
    let meetingId = id;
    try {
      let url = BASE_URL + APIS_ENDPOINT.meetingPublishReport + meetingId;
      let token = APIS_ENDPOINT.bearerToken;
      let meetingDetaile = await GAT_DATA(url, token);
      if (meetingDetaile != null && meetingDetaile != '') {
      setReportMeetingName(meetingDetaile.title);
      let reportAttachmentId = meetingDetaile.reportId;
      setReportMeetingAttechme(meetingDetaile.meetingAttachment);
      setReportSuperintendents(meetingDetaile.reportAttachment)
      let urlAttachments = BASE_URL + APIS_ENDPOINT.gusdreportid + reportAttachmentId;
      if(urlAttachments.length > 0 && urlAttachments != undefined && urlAttachments !=null ){
         await GAT_DATA(urlAttachments, token);
      }
      } else {
        setReportMeetingName("")
      }
    } catch (error) {
      toastBL.current.show({ severity: 'warn', summary: 'error', detail: 'Something went Wrong', life: 3000 });
    }
  }

  const backHandler = () =>{
    navigate(`/${DASHBOARD}`);

  }

  // const previewHandler = () =>{
  // }
  const downloadHandler = async(value) =>{
    if(value != '' && value != null){
    GetAWSObjectURL(value);
     }
  }

  return (
    <>
    <div className='meeting_report_file_container'>
    <div className='meeting_report_file_heading_box'>
          <p onClick={()=>backHandler()} style={{cursor:'pointer'}} className='report-name-second' ><BsArrowLeft size={20} /> Back to Home Page</p>
          <p className='report-date'>{reportMeetingName}</p>
        </div>
      {/* <div className='meeting_report_file_heading_box'>
        <span onClick={()=>backHandler()} style={{cursor:'pointer'}}><BsArrowLeft /> Back to Home Page</span> <br />
        <span>{reportMeetingName}</span>
      </div> */}
      <div className='meeting_report_file_box'>
        <div className='meeting_report_file_headerBox'>
          <h4>Meeting Report & Files</h4>
          <button type="button" className='edit_report_btn'>Edit the Report</button>
        </div>
        <p className='meeting_report_file_cardBox_heading'>Attached Files</p>
        <hr />
        <div className='meeting_report_file_cardBox'>
          {reportMeetingAttechme.length > 0 ?(
            <>
             {reportMeetingAttechme.map((item,i)=>(
              console.log("item",item),
            <>
            <div className='meeting_report_file_cardBox_Body' key={i}>
            <div className='meeting_report_file_cardBox_one'><DocumentDownload size={48} color='#1262D0' />
              {/* <video controls>
              <source src="your_video_file.mp4" type="video/mp4"/>
              Your browser does not support the video tag.
              </video> */}
           </div>
            <div className='meeting_report_file_cardBox_two'>
              <div className='meeting_report_file_mainHeading'>
                <h3 className='meeting_report_file_cardBox_heading'>{item.key}</h3>
                {/* <button type="button" style={{ background: 'transparent', border: 'none' }}><BsThreeDotsVertical /></button> */}
                </div>
              <p className='meeting_report_file_cardBox_subHeading'>Submited By: <span style={{ fontWeight: '600' }}>{item.updateBy}</span></p>
              <p className='meeting_report_file_cardBox_paragraph'>Date:<span style={{ fontWeight: '600' }}>{new Date(item.updateDate).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</span></p>
              <p className='meeting_report_file_cardBox_subParagraph'>format:<span style={{ fontWeight: '600' }}>{item.key != null && item.key != '' && item.key != undefined ? item.key.split('.')[1].toUpperCase() :'no file'}</span></p>
              <div className="act-button-cnt">
                {/* <button type="button" className='meeting_preview_btn meeting_margin_right' onClick={()=>previewHandler()} >Preview <Eye /></button> */}
                <button type="button" className='meeting_download_btn' onClick={()=>downloadHandler(item.key)}>Download <ArrowCircleDown2 /></button></div>
            </div>
          </div></>
            ))}
           </>):
          'No details available'
           }
         </div>

         <p className='meeting_superintendent_file_cardBox_heading'>Superintendents Updates</p>
        <hr />
        <div className='meeting_report_file_cardBox'>
        {reportSuperintendents.length > 0 ?(
            <>
        {reportSuperintendents.map((item)=>(
          console.log(item),
          <>
          <div className='meeting_report_file_cardBox_Body'>
            <div className='meeting_report_file_cardBox_one'><AudioSquare size={48} color='#1262D0' /></div>
            <div className='meeting_report_file_cardBox_two'>
              <div className='meeting_report_file_mainHeading'>
                <h3 className='meeting_report_file_cardBox_heading'>{item.key}</h3>
                <button type="button" style={{ background: 'transparent', border: 'none' }}><BsThreeDotsVertical /></button></div>
              <p className='meeting_report_file_cardBox_subHeading'>Submited By: <span style={{ fontWeight: '600' }}>{item.updateBy}</span></p>
              <p className='meeting_report_file_cardBox_paragraph'>Date:<span style={{ fontWeight: '600' }}>{new Date(item.updateDate).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}</span></p>
              <p className='meeting_report_file_cardBox_subParagraph'>format:<span style={{ fontWeight: '600' }}>{item.key != null && item.key != '' && item.key != undefined ? item.key.split('.')[1].toUpperCase() :'no file'}</span></p>
              <div className="act-button-cnt">
                 {/* <button type="button" className='meeting_preview_btn meeting_margin_right' onClick={()=>previewHandler()} >Preview <Eye /></button> */}
                <button type="button" className='meeting_download_btn' onClick={()=>downloadHandler(item.key)}>Download <ArrowCircleDown2 /></button></div>
            </div>
          </div>
          </>
          ))}
           </>):
          'No details available'
           }
        </div>
      </div>
    </div>
    <Toast ref={toastBL} position="bottom-left" />
    </>
  )
}

export default MeetingDetails;