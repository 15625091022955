import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container } from "reactstrap";
import { USER_MANAGEMENT_ADMIN } from '../../../global/PageNames';
import { GAT_DATA, POST_DATA } from '../../../global/apiType';
import { APIS_ENDPOINT, BASE_URL } from '../../../global/server';

function UserPermission() {

    const navigate = useNavigate();

    var { id } = useParams()

    const [sectionsList, setSectionsList] = useState([]);
    const [currentUserPermissions, setCurrentUserSectionPermissions] = useState([]);
    const [renderItems, setRenderItems] = useState(false);


    useEffect(() => {
        getAllSections()
    }, []);

    const backHandler = () => {
        navigate(`/${USER_MANAGEMENT_ADMIN}`);
    }

    const actionsBodyTemplate = (item) => {
        return (
            <>
                <Checkbox checked={item.checked == 1 ? true : false} onChange={(e) => updateArray(item, e.target.checked)} ></Checkbox>
            </>
        )
    };

    const updateArray = (checkId, isChecked) => {
        let updateNew = [...currentUserPermissions]
        var itemToUpdate = updateNew.find(function (item) {
            return item.id === checkId.id;
        });

        // If the item is found, update the checked status
        if (itemToUpdate) {
            itemToUpdate.checked = isChecked ? 1 : 0;
        }
        setCurrentUserSectionPermissions(updateNew)
    }

    const getAllSections = async () => {
        let url = BASE_URL + APIS_ENDPOINT.allSections;
        let item = JSON.parse(localStorage.getItem('GUSD-user-token'));
        let token = item?.value
        let allSectionsList = await GAT_DATA(url, token);
        if (allSectionsList) {
            setSectionsList(allSectionsList)
            getUserPermissions(allSectionsList)
        }
    }

    const getUserPermissions = async (allSectionsList) => {
        let url = BASE_URL + APIS_ENDPOINT.getUserSectionPermissions + "/" + id;
        let item = JSON.parse(localStorage.getItem('GUSD-user-token'));
        let token = item?.value
        let userSectionPermissionList = await GAT_DATA(url, token);
        if (userSectionPermissionList) {
            const mergedArray = allSectionsList.map(obj => {
                const sectionObj = userSectionPermissionList.find(secObj => secObj.section_id === obj.id);
                // return { ...obj, checked: sectionObj ? 1 : 0 };
                return {
                    id: obj.id,
                    name: obj.name,
                    checked: sectionObj ? 1 : 0
                };
            });
            setCurrentUserSectionPermissions(mergedArray)
            setRenderItems(true)
        }
    }

    const UpdatePermissions = async () => {
        let findCheckedItems = currentUserPermissions.filter(a => a.checked === 1)
        const convertedResponse = findCheckedItems.map(item => ({ "SectionId": item.id }))
        let data = {
            "GoogleUserId": id,
            "SectionPermission": convertedResponse
        }

        let url = BASE_URL + APIS_ENDPOINT.saveUserSectionPermissions;
        let item = JSON.parse(localStorage.getItem('GUSD-user-token'));
        let token = item?.value
        let saveUserSectionPermissionList = await POST_DATA(url, data, token);
        if (saveUserSectionPermissionList) {
            toast.success("Saved.")
            getAllSections()
        }
    }


    return (
        < >
            <Container>
                <div className='mt-10 ]'>
                    <div className='mt-5 d-flex justify-content-between align-items-center'>
                        <p onClick={() => backHandler()} style={{ cursor: 'pointer' }} className='report-name-second' ><BsArrowLeft size={20} /> Go Back</p>
                        <Button style={{ marginRight: 15, backgroundColor: '#113699' }} size='small' label="Save " onClick={() => UpdatePermissions()} />
                    </div>
                </div>

                {
                    renderItems &&
                    <div style={{ marginBottom: 50, marginTop: 30 }}>
                        <DataTable value={currentUserPermissions} >
                            <Column field="name" header="Permissions"></Column>
                            <Column align={"center"} body={actionsBodyTemplate} header="Action"></Column>
                        </DataTable>
                    </div>
                }

                <>
                    <div style={{ textAlign: "center" }}>
                        {
                            !renderItems &&
                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                        }
                    </div>
                </>


            </Container>
        </>
    )
}

export default UserPermission;

