import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/inter";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/800.css";
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";

import PrimeReact from 'primereact/api';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { ToastContainer } from 'react-toastify';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
PrimeReact.appendTo = 'self';
root.render(
  <React.Fragment>
    <BrowserRouter>
        <App />
      <ToastContainer autoClose={1000} />
    </BrowserRouter>
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
