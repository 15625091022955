import React, { useState, useEffect, useContext, useRef } from 'react';
import { Container } from "reactstrap";
import MeetCard from '../../../components/MeetingCard/MeetCard';
import { Link, useNavigate } from 'react-router-dom';
import { CREATE_MEETING, MEETING_DETAILS_FOR_ADMIN, REPORTMEETING_DETAILS, REPORT_SECTION, REPORT_SECTION_FOR_ADMIN, REPORTMEETING_DETAILS_FOR_ADMIN } from '../../../global/PageNames';
import Calendar from '../../../components/Calendar';
import { Form, InputGroup } from 'react-bootstrap';
import './dashboard.css';
import { Toast } from 'primereact/toast';
import { APIS_ENDPOINT, BASE_URL } from "../../../global/server";
import { GAT_DATA } from '../../../global/apiType';
import { AuthContext } from '../../../components/ContextAPI/ContextAPI';
import { ReceiptSearch } from "iconsax-react";

function AdminDashboard() {
  const toastBL = useRef(null);
  const navigate = useNavigate();
  const { setReportCurrentSectionDetails, setReportMeetingDetailsPage, setReportSectionDetails } = useContext(AuthContext);

  //#region All state are define here
  const [dragging, setDragging] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [meetingList, setMeetingList] = useState([]);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [pendingReportDummy, setPendingReportDummy] = useState([]);
  //#endregion

  useEffect(() => {
    pendingReportFun();
    document.body.classList.add('dashboard-layout')
    return () => {
      document.body.classList.remove('dashboard-layout')
    }
  }, []);

  // Check if the item is expired and remove it from local storage
  function checkItemExpiration() {
    const item = JSON.parse(localStorage.getItem('GUSD-user-token'));
    const itemGoogle = JSON.parse(localStorage.getItem('GUSD-user-Google-token'));
    if (item && new Date().getTime() > item?.expirationTime) {
      localStorage.removeItem('GUSD-user-token');
      window.location.reload();
      window.location = '/'
    }

    if (itemGoogle && new Date().getTime() > item?.expirationTime) {
      localStorage.removeItem('GUSD-user-Google-token');
      window.location.reload();
      window.location = '/'
    }
  }

  setInterval(checkItemExpiration, 600000);

  const handleMouseDown = (event) => {
    event.preventDefault();
    setDragging(true);
    setStartX(event.clientX - event.currentTarget.offsetLeft);
    setScrollLeft(event.currentTarget.scrollLeft);
  };

  const pendingReportFun = async () => {
    try {
      let url = BASE_URL + APIS_ENDPOINT.dashboardCurrentReport;
      let urlmeetingdate = BASE_URL + APIS_ENDPOINT.currentMonthMeeting;
      let item = JSON.parse(localStorage.getItem('GUSD-user-token'));
      let token = item?.value
      let reportData = await GAT_DATA(url, token);
      let reportDatameeting = await GAT_DATA(urlmeetingdate, token);
      if (reportDatameeting.length > 0) {
        let meetingDates = reportDatameeting.map(meeting => new Date(meeting.meetingDate));
        setSelectedDates(meetingDates);
        setMeetingList(reportDatameeting)
      } else {
        setSelectedDates([]);
      }
      if (reportData.length > 0) {
        setPendingReportDummy(reportData);
      } else {
        setPendingReportDummy([]);
      }
    } catch (error) {
      toastBL.current.show({ severity: 'warn', summary: 'error', detail: 'Something went wrong, please try again.', life: 3000 });
    }
  }

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseMove = (event) => {
    if (!dragging) return;
    event.preventDefault();
    const x = event.clientX - event.currentTarget.offsetLeft;
    const scrollX = x - startX;
    event.currentTarget.scrollLeft = scrollLeft - scrollX;
  };

  const handeleGoToReportSection = (item) => { // navigate by stauts
    const { status, id } = item;
    switch (status) {
      case 'Progress':
        setReportCurrentSectionDetails(item)
        setReportSectionDetails([])
        localStorage.setItem('report_section_data', JSON.stringify(item));
        localStorage.removeItem("section_data")
        navigate(`/${REPORT_SECTION_FOR_ADMIN}`);
        break;
      case 'Pending':
        setReportCurrentSectionDetails(item)
        navigate(`/${REPORT_SECTION_FOR_ADMIN}/${id}`);
        break;
      case 'Publish':
        //OLD DATA
        setReportMeetingDetailsPage(item)
        navigate(`/${REPORTMEETING_DETAILS_FOR_ADMIN}/${id}`);
        break;
      default:
        break;
    }
  }

  const newMeetingHandler = () => { // function navigate to create function
    navigate(`/${CREATE_MEETING}`);
  }


  const convertTimeTo12HourFormat = (time) => { // time and date function convert to pm am
    const [hours, minutes] = time.split(":");
    let period = "am";
    let hour = parseInt(hours, 10);
    if (hour === 0) {
      hour = 12;
    } else if (hour > 12) {
      hour = hour - 12;
      period = "pm";
    }
    return `${hour}:${minutes} ${period}`;
  }

  //#region format date function like this (2023, May 29th)
  // const getOrdinalSuffix = (day) => {
  //   let suffixes = { 1: 'st', 2: 'nd', 3: 'rd' };
  //   let specialCases = [11, 12, 13];
  //   let lastDigit = day % 10;
  //   console.log("-->",day)
  //   return suffixes[lastDigit] || (specialCases.includes(day) ? 'th' : 'th');
  // }
  const getOrdinalSuffix = (day) => {
    let suffixes = { 1: 'st', 2: 'nd', 3: 'rd' };
    // let specialCases = [11, 12, 13];
    let lastTwoDigits = Math.abs(day % 100); // Get the last two digits of the day

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return 'th';
    }
    let lastDigit = day % 10;
    return suffixes[lastDigit] || 'th';
  };
  const convertYearWithDay = (value) => {
    let date = new Date(value);
    let formattedDate = `${date.getFullYear()}, ${date.toLocaleString('default', { month: 'short' })} ${date.getDate()}${getOrdinalSuffix(date.getDate())}`
    return formattedDate;
  }
  //#endregion

  const convertDatewithyear = (value) => { // format date function like this (May 29, 2023)
    let formattedDate = new Date(value).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    return formattedDate;
  }

  const searcHandler = async (e) => { // Serach meeting handler
    let query = e.target.value;
    let url = BASE_URL + APIS_ENDPOINT.searchmeetingtitle + query;
    let token = APIS_ENDPOINT.bearerToken;
    let reportData = await GAT_DATA(url, token);
    if (reportData.length > 0) {
      setMeetingList(reportData);
    }
  }

  const reportViewFunc = (id) => { // meeting report view
    navigate(`/${MEETING_DETAILS_FOR_ADMIN}/${id}`);
  }


  return (
    < >
      <Container>
        {/* <div className='sub-container'>
          <div>
            <span className='heading-label'>Reports</span>
            <h2 className="h2">Pending for the approval Reports</h2>
          </div>
          <div><button type="button" className='btn see-all-btn'>See all</button></div>
        </div> */}
        {/* <div style={{ display: "flex", overflow: "hidden", gap: 10 }}>
          <MeetCard
            key={"item.id"}
            title={"Board Meeting No.20 May 3, 2022"}
            subtitle={"2023,apr 23rs 04:23 pm"}
            description={"Lorem ipsum dolor sit amet consectetur. Non potenti condimentum dignissim eros."}
            onPress={() => navigate(`/${REPORT_SECTION}`)}
          />
        </div> */}
        {/* <div className='sub-container'>
          <div>
            <span className='heading-label'>Meeting</span>
            <h2 className="h2">Recent Report</h2>
          </div>
         <div><button type="button" className='btn see-all-btn'>See all</button></div>
        </div> */}
        {/* <div className='meetingCard_wrap'>
          {PendingReportDummy.map((item) => (
            <MeetCard
              key={item.id}
              title={item.title}
              subtitle={item.subTitle}
              description={item.dec}
            />
          ))}
        </div> */}

        <div className='sub-container'>
          <div>
            <span className='heading-label'>Meeting</span>
            <h2 className="h2">Pending for Approval Report</h2>
          </div>
          <div><button type="button" className='btn see-all-btn btn-lg'>See all</button></div>
        </div>
        <div className="horizontal-drag-container"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseUp}>
          <div className="scroll-content">
            {pendingReportDummy.map((item) => (
              item.status === "Publish" &&
              <MeetCard
                key={item.id}
                title={item.name}
                subtitle={""}
                description={""}
                status={item.status}
                onPress={() => handeleGoToReportSection(item)}
              />
            ))}
          </div>
        </div>

        <div className='sub-container'>
          <div>
            <span className='heading-label'>Meeting</span>
            <h2 className="h2">Recent Report</h2>
          </div>
          <div><button type="button" className='btn see-all-btn btn-lg'>See all</button></div>
        </div>
        <div className="horizontal-drag-container"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseUp}>
          <div className="scroll-content">
            {pendingReportDummy.map((item) => (
              <MeetCard
                key={item.id}
                title={item.name}
                subtitle={""}
                description={""}
                status={item.status}
                onPress={() => handeleGoToReportSection(item)}
              />
            ))}
          </div>
        </div>
        <div className='schedule_agenda_container'>
          <div>
            <span className='heading-label'>Board of Education Schedule</span>
            <h2 className="h2">Schedule & Agenda</h2>
          </div>
          <div>
            <button type="button" className="btn see-all-btn btn-lg">See all</button>
            &nbsp;<button type="button" className="btn newMeeting-btn btn-lg" onClick={() => newMeetingHandler()}>New Meeting</button>
          </div>
        </div>
        <Calendar
          selectedDates={selectedDates}
        />
        <div className='report_catalog_container'>
          <div>
            <span className='heading-label'>Report</span>
            <h2 className="h2">Report Catalog</h2>
          </div>
        </div>
        <div>
          <div >
            <InputGroup className='input-group search-group' style={{ width: "390px" }}>
              <Form.Control className='form-control py-2 border-right-0 border form-rounded'
                onChange={searcHandler}
                placeholder="search"
              />
              <InputGroup.Text className='input-group-append form-rounded' id="basic-addon2"><ReceiptSearch size={24} color="#98A2B3" /></InputGroup.Text>
            </InputGroup>
          </div>

          <div className='report_catalog_box'>
            {meetingList.map((item, i) => (
              <>
                <div onClick={() => { reportViewFunc(item.id) }} className='report_catalog_Innerbox' key={i}>
                  <h2>{item.title}  {convertDatewithyear(item.meetingDate)}</h2>
                  <span>{convertYearWithDay(item.meetingDate)} {convertTimeTo12HourFormat(item.startTime != undefined && item.startTime != '9pm' ? item.startTime : "00:00")}</span>
                  <div className='mrg_top_bottom'><button type="button" className="report-catalog-btn" >Report</button>
                    &nbsp;&nbsp;<button type="button" className="report-catalog-btn">Video</button></div>
                  <p>{item.subTitle != null ? item.subTitle : "no details"}</p>
                </div>
              </>
            ))}
          </div>

        </div>
        <Toast ref={toastBL} position="bottom-left" />
      </Container>
    </>
  )
}
AdminDashboard.propTypes = {

}

export default AdminDashboard;

