import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useState } from 'react';
import { BsArrowLeft } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container } from "reactstrap";
import { ROLES_MANAGEMENT_ADMIN } from '../../../global/PageNames';
import { GAT_DATA, POST_DATA } from '../../../global/apiType';
import { APIS_ENDPOINT, BASE_URL } from '../../../global/server';
import "./table.css";

function RolesPermissions() {

    const navigate = useNavigate();
    const [permissionsList, setPermissionList] = useState([]);
    const [renderItems, setRenderItems] = useState(false);
    const [permissionNames, setPermissionNames] = useState([]);
    const [roleNames, setRoleNames] = useState([]);


    useEffect(() => {
        getRolesPermissions()
    }, []);

    const backHandler = () => {
        navigate(`/${ROLES_MANAGEMENT_ADMIN}`);
    }

    const getRolesPermissions = async () => {
        let url = BASE_URL + APIS_ENDPOINT.rolePermissions;
        let item = JSON.parse(localStorage.getItem('GUSD-user-token'));
        let token = item?.value
        let rolesPermissionsList = await GAT_DATA(url, token);
        if (rolesPermissionsList) {
            const roleNames = [...new Set(rolesPermissionsList.map(item => item.Roles.map(role => role.RoleName)).flat())];
            const permissionNames = rolesPermissionsList.map(item => item.PermissionName);
            setRoleNames(roleNames)
            setPermissionNames(permissionNames)
            setPermissionList(rolesPermissionsList)
            setRenderItems(true)
        } else {
            setRenderItems(true)
        }
    }

    const handleCheckboxChange = (permissionId, roleId) => {
        setPermissionList((prevPermissions) => {
            return prevPermissions.map((permission) => {
                if (permission.PermissionId === permissionId) {
                    console.log(permission)
                    return {
                        ...permission,
                        Roles: permission.Roles.map((role) => {
                            if (role.RoleId === roleId) {
                                console.log(role)
                                return {
                                    ...role,
                                    IsAccess: role.IsAccess === 1 ? 0 : 1
                                };
                            }
                            return role;
                        })
                    };
                }
                return permission;
            });
        });
    };

    const UpdatePermissions = async () => {
        let selectedRoles = {
            Roles: permissionsList.reduce((acc, permission) => {
                permission.Roles.forEach((role) => {
                    if (role.IsAccess === 1) {
                        let existingRole = acc.find((item) => item.RoleId === role.RoleId);
                        if (existingRole) {
                            existingRole.RolePermission.push({ RolePermissionId: permission.PermissionId });
                        } else {
                            acc.push({
                                RoleId: role.RoleId,
                                RolePermission: [{ RolePermissionId: permission.PermissionId }]
                            });
                        }
                    }
                });
                return acc;
            }, [])
        };
        let url = BASE_URL + APIS_ENDPOINT.saveRolePermissions;
        let item = JSON.parse(localStorage.getItem('GUSD-user-token'));
        let token = item?.value
        let updateRolePermissions = await POST_DATA(url, selectedRoles, token);
        if (updateRolePermissions) {
            toast.success("Saved")
            getRolesPermissions()
        }
    }

    return (
        < >
            <Container>
                <div className='mt-10 ]'>
                    <div className='mt-5 d-flex justify-content-between align-items-center'>
                        <p onClick={() => backHandler()} style={{ cursor: 'pointer' }} className='report-name-second' ><BsArrowLeft size={20} /> Go Back</p>
                        <Button style={{ marginRight: 15, backgroundColor: '#113699' }} size='small' label="Save Permissions " onClick={() => UpdatePermissions()} />
                    </div>
                </div>
                {
                    renderItems &&
                    <>
                        <div className="table-container">
                            <table className="permission-table">
                                <thead>
                                    <tr>
                                        <th>Role Name</th>
                                        {permissionNames.map(permission => (
                                            <th key={permission}>{permission}</th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {roleNames.map(roleName => (
                                        <tr key={roleName}>
                                            <td>{roleName}</td>
                                            {permissionNames.map(permission => {
                                                const permissionItem = permissionsList.find(item => item.PermissionName === permission);
                                                const role = permissionItem.Roles.find(role => role.RoleName === roleName);
                                                const isAccess = role ? role.IsAccess : 0;

                                                return (
                                                    <td key={permission}>
                                                        <Checkbox checked={isAccess === 1} onChange={() => handleCheckboxChange(permissionItem.PermissionId, role.RoleId)} />
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </>
                }
                <>
                    <div style={{ textAlign: "center" }}>
                        {
                            !renderItems &&
                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                        }
                    </div>
                </>
            </Container>
        </>
    )
}

export default RolesPermissions;