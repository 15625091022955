import React from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { DocumentText } from 'iconsax-react';
import PropTypes from "prop-types";


const ReportCard = ({ title, description, primaryBtn,secondaryBtn, onPress, onViewPress }) => {
  return (
    <React.Fragment>
      <Card className='card-report' style={{ width: "21ren" ,minWidth:"21rem"}}>
        <DocumentText
          size="30"
          color="#002870"
        />        <Card.Body>
          <Card.Title>{title ?? ""}</Card.Title>
          <Card.Text>
            {description ?? ""}
          </Card.Text>
          <div className='two-btn-container'>
            <Button variant="primary" className='btn-primary-ghost' onClick={onPress}>{primaryBtn ?? ""}</Button><a onClick={onViewPress} className='view-link'>{secondaryBtn ?? ""}</a>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}

ReportCard.propTypes = {
  title: PropTypes.string,
  primaryBtn: PropTypes.string,
  secondaryBtn: PropTypes.string,
  description: PropTypes.string,
  onPress: PropTypes.func,
  onViewPress: PropTypes.func
};

export default ReportCard;