import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { ADMINDASHBOARD } from "../../global/PageNames";
import { BsArrowLeft } from "react-icons/bs";
import { APIS_ENDPOINT, BASE_URL } from "../../global/server";
import { POST_DATA } from "../../global/apiType";
import { ProgressSpinner } from "primereact/progressspinner";

export default function ViewNotification() {
    var myNavigation = useNavigate();
    //Notifications
    const [notificationList, setNotificationList] = useState([]);
    const [showLoader, setShowLoader] = useState(false);

    async function BindList() {
        const UserId = JSON.parse(localStorage.getItem('userId'));
        let token = APIS_ENDPOINT.bearerToken;
        let url = BASE_URL + APIS_ENDPOINT.userNotification + UserId.value;
        let data = { "start": 0, "limit": 100 }
        const response = await POST_DATA(url, data, token);
        if (response.rows) { setNotificationList(response.rows); setShowLoader(true) }
    }
    useEffect(() => {
        BindList();
    }, []);

    return (
        <div className="report-section-container-grey">
            <div className="p-5">
                <p className="report-name">
                    <BsArrowLeft onClick={() => myNavigation(`/${ADMINDASHBOARD}`)} /> Notifications
                </p>
                <div className="sm:flex items-center ">
                    <div className="text-2xl font-semibold dark:text-white heading-label">Notifications</div>
                </div>
                <div className="notification-details mt-5">
                    {
                        showLoader &&
                        notificationList.map((item, i) => {
                            return (
                                <div key={i.toString()} className="notifications-item">
                                    <div className="text">
                                        <h4>{item.notification_title}</h4>
                                        <p>{item.notification_description}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{ textAlign: 'center', marginTop: "10%" }} className="items-center ">
                    {
                        !showLoader &&
                        <ProgressSpinner />
                    }
                </div>
            </div>
        </div>
    )
}