import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import PropTypes from "prop-types";
// console.log("version", pdfjs.version);

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PDFViewer({ id, comments }) {
  const [allPageNumbers, setAllPageNumbers] = useState('');

  function onDocumentLoadSuccess(pdf) {
    const allPageNumbers = []; // array of numbers
    for (let p = 1; p < pdf.numPages + 1; p++) {
      allPageNumbers.push(p);
    }
    setAllPageNumbers(allPageNumbers);
  }
  // let url = `https://gusd-reportbuilder.hexalytics.in/gusd/report/generate/${id}` ----> UAT Url
    let url = `https://reportbuilder.hexalytics.in/gusd/report/generate/${id}`
  console.log(url)
  return (
    <div id="container">
      <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
        {/* {numbers?.map((number) => ( */}
        {allPageNumbers ? allPageNumbers.map((pn) => (
          <Page
            key={pn}
            renderAnnotationLayer={false}
            pageNumber={pn}
          >
            {
              comments.map((item, i) => {
                return (
                  pn == item.page_no &&
                  <>
                    <div style={{ paddingBlock: 10, paddingInline: 25, marginInline: 20, backgroundColor: "#C24641" }} >
                      <label style={{ color: "#FFF", fontSize: 18 }} key={i.toString()}>{item.comments} </label>
                      <br />
                    </div >
                  </>
                )
              })
            }
          </Page>
        )) : undefined}
      </Document>
    </div>
  );
}

PDFViewer.propTypes = {
  id: PropTypes.string,
  comments: PropTypes.array
};

export default PDFViewer;