import React from 'react';
import PropTypes from 'prop-types';
import './calendar.css';
import { BsFillCaretRightFill, BsFillCaretLeftFill } from "react-icons/bs";
import moment from 'moment';

const date = new Date();
const year = date.getFullYear();
const months = []

const dateStart = moment()
const dateEnd = moment().subtract(12, 'month')

while (dateEnd.isBefore(dateStart, 'day')) {
  months.push(dateEnd.format('MMMM') + " " + year)
  dateEnd.add(1, 'month')
}

function Calendar({ selectedDates, onDateClick }) {
  const getMonthDays = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const renderCalendar = () => {
    return months.map((month, index) => (
      <div className="month-box" key={index}>
        <p style={{ textAlign: 'center' }}>{month}</p>
        <table>
          <thead>
            <tr>
              <th>Su</th>
              <th>Mo</th>
              <th>Tu</th>
              <th>We</th>
              <th>Th</th>
              <th>Fr</th>
              <th>Sa</th>
            </tr>
          </thead>
          <tbody>{renderMonthGrid(year, (moment(month.split(" ")[0], 'MMMM').format('M') - 1))}</tbody>
        </table>
      </div>
    ));
  };

  const renderMonthGrid = (year, month) => {
    const firstDay = new Date(year, month, 1).getDay();
    const daysInMonth = getMonthDays(year, month);
    const weeks = Math.ceil((firstDay + daysInMonth) / 7);
    const monthGrid = [];

    let day = 1;
    for (let i = 0; i < weeks; i++) {
      const week = [];
      for (let j = 0; j < 7; j++) {
        if ((i === 0 && j < firstDay) || day > daysInMonth) {
          week.push(<td key={j}></td>);
        } else {
          const date = new Date(year, month, day);
          const isDateSelected = selectedDates.some((selectedDate) =>
            isSameDate(date, selectedDate)
          );
          week.push(
            <td
              key={j}
              onClick={() => handleDateClick(date)}
            >
              <span className={isDateSelected ? 'selected' : ''}> {day}</span>
            </td>
          );
          day++;
        }
      }
      monthGrid.push(<tr className="" key={i}>{week}</tr>);
    }

    return monthGrid;
  };

  const isSameDate = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };
  const handleDateClick = (date) => {
    if (onDateClick) {
      onDateClick(date);
    }
  };
  function scrollContentLeft() {
    let container = document.querySelector('.calendar_inner_box');
    container.scrollLeft -= 280; // Adjust the scroll amount as needed
  }
  function scrollContentRight() {
    let container = document.querySelector('.calendar_inner_box');
    container.scrollLeft += 280; // Adjust the scroll amount as needed
  }
  return <div className="calendar-container">
    <span className='calendar_arrowLeft_icon' onClick={scrollContentLeft}>
      <BsFillCaretLeftFill />
    </span>
    <span className='calendar_arrowRight_icon' onClick={scrollContentRight}>
      <BsFillCaretRightFill />
    </span>
    <div className='calendar_inner_box' >
      {renderCalendar()}</div></div>;
}

Calendar.propTypes = {
  months: PropTypes.arrayOf(PropTypes.string),
  year: PropTypes.number,
  selectedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  onDateClick: PropTypes.func,
};

export default Calendar;
